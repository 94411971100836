import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/hyperlink/style.scss";
import "@ingka/inline-message/style.scss";
import "@ingka/focus/style.scss";
import style from "./message.module.scss"

import InlineMessage from "@ingka/inline-message";

export default function Message(props) {
  return (
    <div className={style.message}>
      <InlineMessage {...props} />
    </div>
    
  )
}