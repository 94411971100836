import "@ingka/loading/style.scss";
import Loading, { LoadingBall } from "@ingka/loading";
import { useTranslation } from "react-i18next";

export default function Load() {
  const [t] = useTranslation("global")
  return (
    <Loading text={t("components.loading")} >
      <LoadingBall />
    </Loading>
  )
}
