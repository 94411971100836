import style from './datePicker.module.scss'

export default function DatePicker({formField, label, onChange, value, ref}) {
  return (
    <div className={`${formField.className} label-wrapper form-field`}>
    <label className={style.label}>{label}</label>
    <input 
      className={style.input}
      type="date"
      value={value}
      onChange={onChange}
      ref={ref}
    />
    {formField.shouldValidate &&
      <div className="form-field__content">
      <span id={formField.validation.id} className={`helper-text helper-text--${formField.validation.type}`}>
        {formField.validation.msg}
      </span>
    </div>
    }
    
  </div>
  )
}
