import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  strokeWidth: 3,
  strokeColor: "rgb(204, 0, 8)"
};

export const settingSlice = createSlice(
  {
  name: "setting",
  initialState,
  reducers: {
    
    doMode: (state, action) => {
      state.mode = action.payload;
    },

    changeStrokeWidth: (state, action) => {
      state.strokeWidth = action.payload;
    },

    changeStrokeColor: (state, action) => {
      state.strokeColor = action.payload;
    }
  },
});

export const {
  doMode,
  changeStrokeWidth,
  changeStrokeColor
} = settingSlice.actions;
export default settingSlice.reducer;