/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Switch from "../ingka/Switch"
import Select from "./Select";
import { CalculatePolygonSize } from "../../utils/calculateCanvasSize";
import { getSectionsTypes, getSubsectionsTypes, savePolygon } from "../../services/mapServices";
import Load from "../ingka/Load";
import { useDispatch, useSelector } from "react-redux";
import { PixelToPercent } from "../../utils/pointsConverter";
import { createMsgToast } from "../../store/globalSlice";
import Input from "../ingka/Input";
import Text from "../ingka/Text";

export default function AddPolygon({type, polygon, mode, save, disabledSave, points, canvasSize, mapId, parentId, updatePolygonState, fatherValue}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [active, setActive] = useState(1)
  const [polygonType, setPolygonType] = useState(null)
  const [polygonSubType, setPolygonSubType] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])
  const [subtypeOptions, setSubtypeOptions] = useState([])
  const user = useSelector((state) => state.global.user)
  const myStore = useSelector((state) => state.global.myStore)
  const [motionGoal, setMotionGoal] = useState(10)
  const [otherHfb, setOtherHfb] = useState(null)
  const [otherHfbOptions, setOtherHfbOptions] = useState(null)
  const [differentVersion, setDifferentVersion] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)

  useEffect(()=>{
    getSectionsTypes(mode)
    .then(options => {
      setTypeOptions(options)
      if(type === "PUT"){
        setActive(polygon.active)
        setMotionGoal(polygon.motionGoal)
        setPolygonType(options.find(el => el.value === polygon.type.value))
      }
    })
    .finally(() => setLoading(false))
    /* Review if points is edited */
    if(polygon.points !== undefined){
      if(JSON.stringify(points) !== JSON.stringify(polygon.points)){
        setDifferentVersion(true)
      }
    }
    
  },[])

  useEffect(() => {
    /*Review if this polygon has motions for new version*/
    if(differentVersion){
      if(polygon.level === 1 ){ //is section level
        setAlertMessage([
          "Va a editar la forma de su sección por lo que las subsecciones, addons y marcadores de su interior deberán ser revisadas tras aprobar este cambio.", 
          ...(polygon.accumulationMovements !== 0 ? ["Debido a que esta sección ya tiene movimientos asociados se creará una nueva versión de esta entidad."]: [])
        ])
      }else { //is subsection level
        setAlertMessage([
          ...(polygon.accumulationMovements !== 0 ? ["Debido a que esta sección ya tiene movimientos asociados se creará una nueva versión de esta entidad."]: [])
        ])
      }
    }
    
  },[differentVersion])

  useEffect(() => {
    setLoading(true)
    if(polygonType && polygonType.hasSubOption){
      getSubsectionsTypes(polygonType, fatherValue).then(options => {
        setSubtypeOptions(options)
        if(type === "PUT"){
          setPolygonSubType(options.find(el => el.value === polygon.subType.value))
        }
      })
      .finally(() => setLoading(false))
    }

    if(polygonType && polygonType.value === "4" && mode === "subsection"){ // is other HFB in subsection
      setOtherHfb(null)
      getSubsectionsTypes({nameSubOption:"HFBs", value: "1"}, fatherValue).then(options => {
        setOtherHfbOptions(options)
      })
      .finally(() => setLoading(false))
    } else{
      setLoading(false)
    }
  },[polygonType])

  useEffect(() => {
    setLoading(true)
    setSubtypeOptions(null)
    setPolygonSubType(null)
    if(otherHfb){
      //console.log(otherHfb)
      getSubsectionsTypes({nameSubOption:"PAs", value: "1"}, otherHfb.value).then(options => {
        setSubtypeOptions(options)
        if(type === "PUT"){
          setPolygonSubType(options.find(el => el.value === polygon.subType.value))
        }
      })
      .finally(() => setLoading(false))
    }
  },[otherHfb])

  useEffect(() =>{
    if((polygonType && polygonSubType) || (polygonType && !polygonType.hasSubOption)){
      disabledSave(false)
    }
  },[active,polygonType,polygonSubType])

  useEffect(() => {
    if(save){
      setLoading(true)
      const polygonSize = CalculatePolygonSize(points, canvasSize.w, canvasSize.h)
      const pointsCopy = JSON.parse(JSON.stringify(points));
      const pointsPercent = JSON.stringify(PixelToPercent(pointsCopy, canvasSize.w, canvasSize.h))

      const data = {
        width: polygonSize.w,
        height: polygonSize.h,
        storeId: myStore.id,
        point: pointsPercent,
        level: mode === "section" ? 1 : 2,
        map_id: mapId,
        parentSection_id: mode === "section" ? null : parentId,
        active: active ? 1 : 0,
        userId: user,
        typeId: polygonType.value,
        subTypeId: polygonSubType ? polygonSubType.value : null,
        motionGoal: motionGoal,
        name: polygonType.label + (polygonSubType ? " - "+polygonSubType.label : ''),
        ...(differentVersion && polygon.accumulationMovements !== 0 ? {version: {
            width:polygon.width,
            height: polygon.height,
            point: polygon.point,
            name: polygon.name,
            date: polygon.modificationDate ? polygon.modificationDate : polygon.creationDate
        }} : {})
      }
      //console.log(points, polygon, data, differentVersion)
      savePolygon(type, data, polygon).then((response) => {
        dispatch(createMsgToast({body: t("map.success"+type+mode), variant:"positive"}))
        updatePolygonState(response)
      }).finally(() => setLoading(false))
      

      
    }
  },[save])

  return (
    <>
    {loading &&
      <Load />
    }
    {!loading &&
      <div style={{minHeight: 300}}>
        {type === "PUT" &&
          <div className="col-12" style={{display: "flex",justifyContent: "end"}}>
            <Switch
              props={{
                checked: active,
                onChange: (e)=> setActive(e.target.checked)}
            } 
            />
          </div>
        }
        <Select 
          formField={{className:"col-12"}}
          label={t("map.polygonType")}
          defaultValue={polygonType}
          onChange={(val) => {
            setPolygonType(val)
            setPolygonSubType(null)
          }}
          isClearable={false}
          isSearchable={false}
          id="type"
          name="type"
          options={typeOptions}
        />

        {otherHfbOptions && polygonType.value === "4" && mode === "subsection" &&
          <Select 
            formField={{className:"col-12"}}
            label={t("map.otherHfb")}
            defaultValue={otherHfb}
            value={otherHfb}
            onChange={(val) => setOtherHfb(val)}
            isClearable={false}
            isSearchable={true}
            id="otherHfb"
            name="otherHfb"
            options={otherHfbOptions}
          />
        }

        {polygonType && polygonType.hasSubOption &&
          <Select 
            formField={{className:"col-12"}}
            label={t("map.polygonSubType")}
            value={polygonSubType}
            defaultValue={polygonSubType}
            onChange={(val) => setPolygonSubType(val)}
            isClearable={false}
            isSearchable={true}
            id="subtype"
            name="subtype"
            options={subtypeOptions}
          />
        }

        <Input 
          formField={{
            className: "col-12"
          }}
          input={{
            label: t("map.motionGoal"+mode),
            type:"number",
            value: motionGoal,
            onChange: e => setMotionGoal(e.target.value)
          }}
        />
        {differentVersion &&
          alertMessage.map( (msg, index) => (
            <Text key={index} tagName="p" headingSize="s" className="text-red">{msg}</Text>
          ))
        }
      </div>
    }
    </>
  )
}
