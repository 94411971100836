import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';

export const getAllUsers =  () => {
  const fetchSettings = {
    method: "GET",
    url: 'employees'
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      response.forEach(el => {
        el.label = el.mail
        el.value = el.employeeId
        if(el.roles.length > 0) el.roleName = el.roles[0].name
      })
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getRoles =  () => {
  const fetchSettings = {
    method: "GET",
    url: 'roles'
  }
  const rol = JSON.parse(window.localStorage.getItem('rol'))

  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      response.forEach(el => {
        el.label = el.name
        el.value = el.id
      })
      if(rol.name === "ADMIN"){ //is ADMIN
        resolve(response.filter(opt => opt.name !== "SUPERADMIN"))
      }else{
        resolve(response)
      }
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const saveUserRol =  (data) => {
  const fetchSettings = {
    method: "POST",
    url: 'assignRoleToEmployee',
    data : data
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}