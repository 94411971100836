import { useSelector } from "react-redux";
import MsgToast from "./components/ingka/MsgToast";
import PublicRouter from "./router/PublicRouter";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


export default function App() {
  const toasts = useSelector((state) => state.global.toasts);
  const [t,i18n] = useTranslation("global")
  const lang = useSelector((state) => state.global.language)

  //change languaje from localStorage lang when refresh app
  useEffect(()=>{
    i18n.changeLanguage(lang)
  },[lang])

  return (
    <>
    <div className="toastsContainer">
      {toasts?.map((toast, index) => (
        <MsgToast
        key={index}
        id={index}
        body={toast.body}
        variant={toast.variant}
        title={toast.title}/>
      ))}
    </div>
      <PublicRouter />
    </>
  )
}

