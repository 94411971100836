import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Input from "../components/ingka/Input";
import { Controller, useForm } from "react-hook-form";
import Button from "../components/ingka/Button";
import Select from "../components/global/Select";
import { iconsIngka } from "../utils/icons";
import back from '@ingka/ssr-icon/paths/arrow-left'
import { getListActions, saveAction } from "../services/settingsServices";
import { useDispatch, useSelector } from "react-redux";
import { createMsgToast } from "../store/globalSlice";
import Switch from "../components/ingka/Switch";


export default function Action() {
  const [t] = useTranslation("global")
  const {actionId} = useParams()
  const { handleSubmit, control, reset } = useForm();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const iterations = [
    {value: null, label: t('global.none')},
    {value: "input", label: t('iterations.input')},
    {value: "select", label: t('iterations.select')},
  ]

  const [title, setTitle] = useState("")
  const [icon, setIcon] = useState("")
  const [iconName, setIconName] = useState("")
  const [description, setDescription] = useState("")
  const [iteration, setIteration] = useState(null)
  const [iterationLabel, setIterationLabel] = useState("")
  const [iterationOptions, setIterationOptions] = useState("")
  const [active, setActive] = useState(null)
  const myStore = useSelector((state) => state.global.myStore)
  const user = useSelector((state) => state.global.user)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(actionId){
      
      setLoading(true)
      //get para traer la acción
      getListActions().then((actions)=>{
        const act = actions.find(action => action.id == actionId)
        //console.log("Action:", act)
        setTitle(act.title)
        setIcon(act.icon)
        setIconName(act.iconName)
        setDescription(act.description)
        setIteration(act.iteration)
        setIterationLabel(act.iterationLabel)
        setIterationOptions(act.iterationOptions)
        setActive(act.active)
        reset(
          { title: act.title,
          icon: act.icon,
          iterationLabel: act.iterationLabel,
          iterationOptions: act.iterationOptions }
      );
      }).finally(() => {
        setLoading(false)
      })
    }
  },[])

  const goToSaveAction = () => {
    const type = actionId ? "PUT" :"POST"
    //console.log(icon)
    const iconPath = []
    if(Array.isArray(icon)){
      icon.forEach(ic => {
        if(ic !== "  " && ic instanceof Object){
          iconPath.push(ic.props.d)
        }else{
          iconPath.push(ic)
        }
      })
    }else{
      iconPath.push(icon.props.d)
    }
    
    setLoading(true)
    const data= {
      title: title,
      description: description,
      icon: JSON.stringify(iconPath),
      iconName: iconName,
      iteration: iteration,
      iterationLabel: iterationLabel,
      iterationOptions: iterationOptions.replaceAll(' , ', ',').replaceAll(', ', ',').replaceAll(' ,', ','),
      userId: user,
      storeId: myStore.id,
      active: active 

    }
    //console.log(data)
    saveAction(data, type, actionId).then(()=>{
      dispatch(createMsgToast({body: t('actions.success'+type+'Action'), variant:"positive"}))
      navigate("/actions")
    }).finally(setLoading(false))
  }

  return (
    <div>
      <div style= {{display: "flex", alignItems: "center"}}>
        <Button 
          type="tertiary" 
          iconOnly 
          small
          ssrIcon={back} 
          onClick={() => navigate(-1, {replace: true})}
        />
        <Text tagName="h2" headingSize="m">{actionId ? t('actions.editAction') : t('actions.newAction')}</Text>
      </div>
      
      <form  onSubmit={handleSubmit(goToSaveAction)}>
        <div className="row">
          {actionId &&
            <div className="col-12" style={{display: "flex", justifyContent: "end"}}>
              <Switch
                props={{
                  defaultChecked: active,
                  onChange: e => setActive(e.target.checked)
                }} 
              />
            </div>
          }
          {/* TITLE */}
          <Controller
            control={control}
            name="title"
            rules={{required: true}}
            render={({ field:{ onChange, ref}, fieldState: {error}}) => (
            <Input
              formField={{
                className: "col-lg-8 col-md-8 col-sm-12",
                shouldValidate: error !== undefined,
                validation:{
                  id:"error-msg-description",
                  msg:t("validation.required"),
                  type: "error"
                }
              }}
              input={{
                label: t("global.title"),
                value: title,
                onChange: e => {
                  onChange(e.target.value)
                  setTitle(e.target.value)
                },
              }}
              inputRef={ref}
            />
            )} 
          />

          {/* ICON */}
          <Controller
            control={control}
            name="icon"
            rules={{required: true }}
            render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
            <Select
              formField={{
                className:"col-lg-4 col-md-4 col-sm-12",
                shouldValidate: error !== undefined,
                validation:{
                  id:"error-msg-contributor",
                  msg:t("validation.required"),
                  type: "error"
                }
              }}
              label={t("global.icon")}
              value={iconsIngka.find((c) => c.value === iconName)}
              onChange={val => {
                onChange(val.value)
                setIconName(val.value)
                setIcon(val.label.props.children)
              }}
              inputRef={ref}
              options={iconsIngka}
              isClearable={false}
              isSearchable={true}
              id="icon"
            />
            )} 
          />

          {/* DESCRIPTION */}
          <Input
            formField={{
              className: "col-lg-12 col-md-12 col-sm-12",
            }}
            input={{
              label: t("global.description"),
              value: description,
              onChange: e => {setDescription(e.target.value)}
            }}
          />

          {/* ITERATION */}
          <Select
            formField={{
              className:"col-lg-4 col-md-4 col-sm-12",
            }}
            label={t("actions.actionIteration")}
            value={iterations.find((c) => c.value === iteration)}
            onChange={val => {setIteration(val.value)}}
            options={iterations}
            id="iterations"
          />
          {iteration && iteration.value !== "none" &&
            /* ITERATION LABEL */
            <>
            <Controller
            control={control}
            name="iterationLabel"
            rules={{required: true}}
            render={({ field:{ onChange, ref}, fieldState: {error}}) => (
            <Input
              formField={{
                className: "col-lg-8 col-md-8 col-sm-12",
                shouldValidate: error !== undefined,
                validation:{
                  id:"error-msg-description",
                  msg:t("validation.required"),
                  type: "error"
                }
              }}
              input={{
                label: t("global.label"),
                value: iterationLabel,
                onChange: e => {
                  onChange(e.target.value)
                  setIterationLabel(e.target.value)
                },
              }}
              inputRef={ref}
            />
            )} 
          />
          {iteration && iteration === "select" &&
            /* ITERATION OPTIONS if is select iteration */
            <Controller
            control={control}
            name="iterationOptions"
            rules={{required: true}}
            render={({ field:{ onChange, ref}, fieldState: {error}}) => (
            <Input
              formField={{
                className: "col-lg-12 col-md-12 col-sm-12",
                shouldValidate: error !== undefined,
                validation:{
                  id:"error-msg-description",
                  msg:t("validation.required"),
                  type: "error"
                },
                fieldHelper:{
                  id: 'helper-msg-id',
                  msg: t('actions.optionsInstructions'),
                  type: ''
                }
              }}
              input={{
                label: t("iterations.options"),
                value: iterationOptions,
                onChange: e => {
                  onChange(e.target.value)
                  setIterationOptions(e.target.value)
                },
              }}
              inputRef={ref}
            />
            )} 
          />
          }
          </>
          }
        </div>
        <div className="submit-buttons-bottom">
          <Button
            type="emphasised"
            text={t("global.save")}
            htmlType="submit"
            disabled={loading}
          />
        </div>
      </form>
    </div>
  )
}
