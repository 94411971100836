import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maps: JSON.parse(localStorage.getItem("maps")),
  allMaps: !!localStorage.getItem("allMaps"),
  actions: JSON.parse(localStorage.getItem("actions")),
};

export const canvasSlice = createSlice(
  {
  name: "canvas",
  initialState,
  reducers: {
    
    setAllMaps: (state, action) => {
      state.maps = action.payload;
      localStorage.setItem("maps", JSON.stringify(state.maps))
      state.allMaps = true;
      localStorage.setItem("allMaps", state.allMaps)
    },
    clearAllMaps: (state, action) => {
      state.maps = null;
      localStorage.setItem("maps", JSON.stringify(state.maps))
      state.allMaps = false;
      localStorage.setItem("allMaps", state.allMaps)
    },

    addToMaps: (state, action) => {
      state.maps = [...state.maps, action.payload];
      localStorage.setItem("maps", JSON.stringify(state.maps))
    },

  },
});

export const {
  setAllMaps,
  addToMaps,
  clearAllMaps
} = canvasSlice.actions;
export default canvasSlice.reducer;

