import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import style from './select.module.scss'
import { useTranslation } from 'react-i18next';

export default function Sel({formField, label, onChange, value, ref, name, options, isClearable, isSearchable, defaultValue, placeholder, closeMenuOnSelect, isMulti}) {
  const animatedComponents = makeAnimated();
  const [t] = useTranslation("global")
  return (
    <div className={`${formField.className} label-wrapper form-field`}>
      <label className={style.label}>{label}</label>
      <Select
        placeholder={placeholder || t('components.select.selectMultiple') }
        className={style.select}
        closeMenuOnSelect={closeMenuOnSelect || true}
        components={animatedComponents}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        ref={ref}
        onChange={onChange}
        value={value}
        name={name}
        defaultValue={defaultValue}
        isMulti={isMulti}
      />
      {formField.shouldValidate &&
        <div className="form-field__content">
        <span id={formField.validation.id} className={`helper-text helper-text--${formField.validation.type}`}>
          {formField.validation.msg}
        </span>
      </div>
      }
      
    </div>
    
  )
}
