import { useTranslation } from "react-i18next";
import RadioGroup from "../ingka/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { changeStrokeColor, changeStrokeWidth, doMode } from "../../store/settingSlice";
import SliderInput from '../ingka/SliderInput';
import { CirclePicker } from 'react-color';
import Text from "../ingka/Text"

export default function Settings({canvasMode, paintControl}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.setting.mode)
  const strokeWidth = useSelector((state) => state.setting.strokeWidth)
  const strokeColor = useSelector((state) => state.setting.strokeColor)

  const changeMode = (e) => {
    dispatch(doMode(e.target.value))
  }

  return (
    <div>
    {canvasMode &&
      <>
        <RadioGroup
        radio={{
          list: [
            {
              disabled: false,
              id: 'light',
              label: t('global.light'),
              value: 'light',              
              defaultChecked: mode === "light",
              onChange: changeMode,
            },
            {
              disabled: false,
              id: 'dark',
              label: t('global.dark'),
              value: 'dark',
              defaultChecked: mode === "dark",
              onChange: changeMode,
            },
          ],
          name: t('settings.canvasMode')
        }}/>
        {paintControl &&
          <>
            <SliderInput 
              className="mt-3"
              id="strokeWidth"
              label={t('settings.drawWidth')}
              margin={0}
              range={{
                max: 10,
                min: 1
              }}
              start={[
                strokeWidth
              ]}
              step={1}
              ariaFormat={{
                from: function noRefCheck(){},
                to: function noRefCheck(){}
              }}
              onChange={(e) => dispatch(changeStrokeWidth(e[0]))}
            />
            <Text tagName="p" bodySize="l" className="mt-3">{t('settings.colorPicker')}</Text>
            <CirclePicker
              className="w-100"
              color={strokeColor}
              onChangeComplete={(color) => dispatch(changeStrokeColor(color.hex))}
            />
          </>
        }
      </>
    } 
      
    </div>
  )
}
