import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Message from "../components/ingka/Message";
import style from "../assets/scss/acctionMessage.module.scss"
import arrowR from '@ingka/ssr-icon/paths/arrow-right'
import plus from '@ingka/ssr-icon/paths/plus'
import React from "react";
import Button from "@ingka/button";
import { getListActions } from "../services/settingsServices";
import Load from "../components/ingka/Load";
export default function Actions() {
  const [t] = useTranslation("global")
  const navigate = useNavigate()

  const [actions, setActions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getListActions().then((actions)=>{
      setActions(actions)
    }).finally(setLoading(false))
  },[])
  return (
    <div>
      <Text tagName="h2" headingSize="m">{t(`actions.title`)}</Text>
      <div className="row">
      {loading &&
        <Load />
      }
      {!loading && actions && actions.map(action => (
        <div className="col-lg-4 col-md-6 col-sm-12" key={action.id}>
          <Message
            title={
              <div className={style.actionCard}>
                <svg className={style.icon}>
                  {action.icon.map((path, index) =>(
                    <path key={index} fillRule="evenodd" clipRule="evenodd" d={path}></path>
                  ))
                  }
                </svg>
                <div className={style.content}>
                  <Text tagName="h3" headingSize="s">{action.title}</Text>
                  <div >
                    <Text tagName="p" bodySize="s">{action.description}</Text>
                  </div>
                </div>
              </div>
            }
            variant={action.active ? "informative" : "cautionary"}
            actions={!action.required ? [
              {
                onClick: ()=>navigate(`/actions/${action.id}`),
                iconOnly: true,
                ssrIcon: arrowR,
                className:"goToButton"
              }
            ]: []}
          />
        </div>
        
      ))
      }
      </div>
      <div className="submit-buttons-bottom">
        <Button
        type="secondary" 
        text={t("actions.newAction")}
        ssrIcon={plus} 
        onClick={() => { navigate('/actions/new')}}
        />
      </div>
    </div>
  )
}
