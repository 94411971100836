import { MaterialReactTable } from 'material-react-table'
import React, { useEffect, useRef, useState } from 'react'
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default function Table({columns, data, short, colPing,enableSorting=true, enableRowActions, enablePagination=false, renderRowActions, pageChange, pageInfo={pageCount:0, pageSize:0}}) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  useDidMountEffect(() => {
    pageChange(pagination.pageIndex)
  },[pagination]);

  return (
    <MaterialReactTable
      columns={columns} 
      data={data}
      enableSorting={enableSorting}
      enableColumnActions={false}
      enableBottomToolbar={enablePagination}
      enableTopToolbar={false}
      enablePagination={enablePagination}
      muiTableBodyRowProps={{ hover: false }}
      enablePinning
      initialState={{
        density: 'compact',
        sorting: short === undefined ? [] : [{...short}],
        columnPinning: { left: [colPing]}
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: "none"
        },
      }}

      muiTableBodyProps={{
        sx: {
          '& tr td': {
            borderBottom: "none",
            padding: "0.8rem"
          },
          '& tr:nth-of-type(odd) > td': {
            backgroundColor: 'rgb(0, 124, 193, 0.08)',
          },
        }
      }}

      muiTableHeadCellProps={{
        sx: {
          borderBottom: "2px solid rgb(223, 223, 223)",
        },
      }}
      muiTableBodyCellProps={({ row, cell }) => ({
          style: row.original.selected ? {backgroundColor: 'rgb(254 234 226)'}:{}
        })
      }
      enableRowActions={enableRowActions}
      positionActionsColumn="last"
      renderRowActions={renderRowActions}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: ''
      },}}
      muiPaginationProps= {{
        shape: 'rounded',
        showRowsPerPage: false,
        variant: 'outlined',
      }}
      paginationDisplayMode='pages'
      manualPagination={true}
      state={{pagination}}
      onPaginationChange={setPagination}
      rowCount={pageInfo.rowCount}
      pageCount={pageInfo.pageCount}
    />
  )
}
