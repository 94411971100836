import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./globalSlice";
import settingSlice from "./settingSlice";
import canvasSlice from "./canvasSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    setting: settingSlice,
    canvas: canvasSlice
  },
});