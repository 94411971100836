import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Dashboard from '../views/Dashboard';
import NewMotion from '../views/NewMotion';
import YourMotions from '../views/YourMotions';
import Motions from '../views/Motions';
import Maps from '../views/Maps';
import Map from '../views/Map';
import Actions from '../views/Actions';

import Header from '../components/global/Header';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { updatePage, updatePath } from '../store/globalSlice';
import Section from '../views/Section';
import Action from '../views/Action';
import notFound from '../views/NotFound';
import Users from '../views/Users';



export default function PrivateRouter() {
  const dispatch = useDispatch();
  const RoutesMaster = [
    {
      path: "dashboard",
      Element: Dashboard
    },
    {
      path: "motion",
      Element: NewMotion
    },
    {
      path: "motion/:motionId",
      Element: NewMotion
    },
    {
      path: "your-motions",
      Element: YourMotions
    },
    {
      path: "motions",
      Element: Motions
    },
    {
      path: "maps",
      Element: Maps
    },
    {
      path: "map/:mapId",
      Element: Map
    },
    {
      path: "map/:mapId/section/:sectionId",
      Element: Section
    },
    {
      path: "actions",
      Element: Actions
    },
    {
      path: "actions/new",
      Element: Action
    },
    {
      path: "actions/:actionId",
      Element: Action
    },
    {
      path: "users",
      Element: Users
    },
    {
      path: "not-found",
      Element: notFound
    },
  ]
  
  let location =  useLocation();
  let paths = location.pathname.split('/')
  let page = null;

  //set first path for page 
  if(paths.length > 1){
    page = paths[1]
  }

  //location change
  useEffect(() => {
    dispatch(updatePath(location.pathname))
    dispatch(updatePage(page))
  }, [location]);

  return (
    <>
      <header id="header">
        <Header/>
      </header>
      <div className="container">
        <Routes>
          <Route path='/' element={<Navigate to="dashboard" />}/>
          {
            RoutesMaster.map(({path, Element}, index) => (
              <Route path={path} element={<Element />} key={index}/>
            ))
          }
        </Routes>
      </div>
    </>
  )
}
