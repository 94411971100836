import "@ingka/svg-icon/style.scss";
import "@ingka/forms/style.scss";
import FormField from "@ingka/form-field";
import style from "./select.module.scss"

import { Select, Option } from "@ingka/select";

export default function SelectIngka({formField, select, options}) {
  return (
    <FormField
      {...formField}
      className={style.formField}
    >
      <Select
        className={style.select}
        hintText={false}
        {...select}
      >
        {options.map( option => (
          <Option
            key={option.value}
            name={option.name}
            value={option.value}
          />
        ))}
      </Select>
    </FormField>
  )
}
