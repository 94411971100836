import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/hyperlink/style.scss";
import "@ingka/inline-message/style.scss";
import "@ingka/focus/style.scss";
import InlineMessage from "@ingka/inline-message";
import { useDispatch } from "react-redux";
import { deleteMsgToast } from '../../store/globalSlice';
import { useEffect } from "react";
import style from './msgToast.module.scss';

export default function MsgToast(props) {
  const dispatch = useDispatch();
  const closeToast = (e) =>{
    dispatch(deleteMsgToast(parseInt(e.target.id)))
  }

  useEffect(()=>{
    setTimeout(()=>{
      dispatch(deleteMsgToast(props.id))
    },6000)
  },[])

  return (
    <InlineMessage
      className={style.toast}
      {...props}
      dismissable
      onDismissClick={closeToast}
    />
  )
}
