import { useNavigate } from "react-router-dom"
import canvasStyle from "../../assets/scss/canvas.module.scss"
import { useEffect, useRef, useState } from "react"
import Button from "../ingka/Button"
import TextComponent from "../ingka/Text"
import { useTranslation } from "react-i18next"

import space from "@ingka/ssr-icon/paths/selection-add";
import draw from "@ingka/ssr-icon/paths/define-space";
import move from "@ingka/ssr-icon/paths/hand-move";
import save from "@ingka/ssr-icon/paths/database";
import enter from "@ingka/ssr-icon/paths/arrow-right-to-base";
import edit from "@ingka/ssr-icon/paths/pencil";
import setting from "@ingka/ssr-icon/paths/filters";
import mapIcon from "@ingka/ssr-icon/paths/map";
import addonIcon from "@ingka/ssr-icon/paths/cube-3d";
import markerIcon from "@ingka/ssr-icon/paths/location-pin-filled";
import del from "@ingka/ssr-icon/paths/trash-can";
import routeIcon from "@ingka/ssr-icon/paths/arrow-on-path";
import { Arrow, Circle, Layer, Line, Path, Stage, Group, Rect } from "react-konva"
import { PercentToPixel, PixelToPercent } from "../../utils/pointsConverter"


import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/modal/style.scss";
import "@ingka/focus/style.scss";
import Settings from './Settings';
import AddMap from "./AddMap"
import AddPolygon from "./AddPolygon"
import { useDispatch, useSelector } from "react-redux"
import AddAddon from "./AddAddon"
import { updateRoute } from "../../store/globalSlice"
import { changeMapRoute, deleteAddon } from "../../services/mapServices"

export default function CanvasMap({canvasSize, map, type, backgroundSize, backgroundPosition, area, getData, level}) {
  const [t] = useTranslation("global")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [polygons, setPolygons] = useState(null)
  const [lastPolygons, setLastPolygons] = useState(null)
  const [lastAddons, setLastAddons] = useState(null)
  const [addon, setAddon] = useState(null)
  const [route, setRoute] = useState(null)
  const [addons, setAddons] = useState(null)
  const [polygonButtons, setPolygonButtons] = useState(false)
  const [drawButton, setDrawButton] = useState(false)
  const [moveButton, setMoveButton] = useState(false)
  const [markerButtons, setMarkerButtons] = useState(false)
  const [addonButtons, setAddonButtons] = useState(false)
  const [routeButtons, setRouteButtons] = useState(false)
  const canvas = useRef()
  const canvasContainer = useRef()

  const [points, setPoints] = useState([])
  const [lastPoints, setLastPoints] = useState(null)
  
  const [curMousePos, setCurMousePos]= useState([])
  const [isPolygon, setIsPolygon] = useState(false)
  const [polygonSelected, setPolygonSelected] = useState(null)
  const [polygonEditing, setPolygonEditing] = useState(null)
  const [addonSelected, setAddonSelected] = useState(null)
  const [addonEditing, setAddonEditing] = useState(null)
  const [modalActive, setModalActive] = useState(false)
  const [modalAction, setModalAction] = useState(null)
  const [saveModal, setSaveModal] = useState(false)
  const [disabledSave, setDisabledSave] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [cursor, setCursor] = useState("pointer")

  const canvasMode = useSelector((state) => state.setting.mode)

  useEffect(()=>{
    if(canvasSize){
      if(map.polygons !== null){
        const polys = map.polygons.map((poly) => {
          const arrPoint = JSON.parse(poly.point)
          poly.points = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
          return poly
        })
        //console.log("POLYS",polys)
        setPolygons(polys)
      }
      
      if(map.route !== null && map.route !== undefined){
        const arrPoints = JSON.parse(map.route.point)
        const points = PercentToPixel(arrPoints, canvasSize.w, canvasSize.h)
        setRoute({id: map.route.id, point: points})
      }

      if(map.addons !== null && map.addons.length > 0){
        const adds = map.addons.map((addon) => {
          const arrPoint = JSON.parse(addon.point)
          addon.point = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
          return addon
        })
        //console.log("Addons comienzo",adds)
        setAddons(adds)
      }
    }
  },[canvasSize])

  

  const getMousePos = (stage) => {
    return [Math.round(stage.getPointerPosition().x), Math.round(stage.getPointerPosition().y)];
  };

  const handleCanvasClick = (event) =>{
    const stage = event.target.getStage()
    const mousePos = getMousePos(stage)

    if(drawButton){
      if(!moveButton){
        if(!addonButtons && !markerButtons){ //is polygon or line for route
          setPoints([...points, mousePos])
        } else { //is addon
          if(!addon){
            setAddon(mousePos)
            setDrawButton(false)
            setMoveButton(true)
            setCursor("move")
          }
        }
      }
    }
  }
  useEffect(()=>{
    if(points.length >= 3){
      setIsPolygon(true)
    }
  },[points])

  useEffect(() => {
    switch (cursor) {
      case "pointer":
        canvas.current.style.cursor = "pointer"
        break;
      case "copy":
        canvas.current.style.cursor = "copy"
        break;
      case "move":
        canvas.current.style.cursor = "move"
        break;
      case "crosshair":
        canvas.current.style.cursor = "crosshair"
        break;
      default:
        canvas.current.style.cursor = "pointer"
        break;
    }
  }, [cursor])

  const handleCanvasMouseMove = (event) =>{
    if(moveButton){
      const stage = event.target.getStage();
      const mousePos = getMousePos(stage);
      if(!isEditing && !routeButtons){
        setCurMousePos(mousePos)
      }
    }
  }

  const handleDragMovePoint = (event) => {
    const index = event.target.index - 1;
    const pos = [event.target.attrs.x, event.target.attrs.y];
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)])
  };
  const handleDragMoveAddon = (event) => {
    setAddon([event.target.attrs.x, event.target.attrs.y])
  };

  const flattenedPoints = points
      .concat(isPolygon ? [] : curMousePos)
      .reduce((a, b) => a.concat(b), []);

    
  const newPolygon = () => {
    if(polygonButtons){
      setIsNew(false)
      setPolygonButtons(!polygonButtons)
      setPoints([])
      setDrawButton(false)
      setMoveButton(false)
      setCursor("pointer")
    } else{
      setIsNew(true)
      setPolygonButtons(!polygonButtons)
      setMarkerButtons(false)
      setAddonButtons(false)
      setAddon(null)
      setRouteButtons(false)
      setDrawButton(true)
      setCursor("copy")
    }
    
  }
  const deletePoints = () => {
    setPoints([])
    setDrawButton(true)
    setMoveButton(false)
    setCursor("crosshair")
  }

  const clickPolygon = (pol) => {
    setAddonSelected(null)
    setIsEditing(false)
    if(polygonSelected === null){
      setPolygonSelected(pol)
    } else {
      if(polygonSelected.id === pol.id){
        setPolygonSelected(null)
      } else {
        setPolygonSelected(pol)
      }
    }
  }

  const clickAddon = (add) => {
    setPolygonSelected(null)
    setIsEditing(false)
    if(addonSelected === null){
      setAddonSelected(add)
    } else {
      if(addonSelected.id === add.id){
        setAddonSelected(null)
      } else {
        setAddonSelected(add)
      }
    }
  }

  const goToPolygon = () => {
    dispatch(updateRoute({
      id:polygonSelected.id, 
      title: polygonSelected.type.label+(polygonSelected.subType ? " - "+polygonSelected.subType.label : ""), 
      path:`/map/${map.id}/section/${polygonSelected.id}`
    }))
    navigate(`/map/${map.id}/section/${polygonSelected.id}`)
  }

  const editPolygon = () => {
    if(isEditing){
      setIsEditing(false)
      setPoints([])
      setPolygonEditing(null)
      setPolygonButtons(false)
      setPolygonSelected(null)
      setDrawButton(false)
      setMoveButton(false)
      setPolygons([...lastPolygons])
      setCursor("pointer")
      
    } else {
      setIsEditing(true)
      setLastPolygons([...polygons])
      setPolygons([...polygons.filter(el => el.id !== polygonSelected.id)])
      setPoints(polygonSelected.points)
      setPolygonEditing(polygonSelected)
      setPolygonButtons(true)
      setPolygonSelected(null)
      setDrawButton(false)
      setMoveButton(true)
      setCursor("move")
    }
  }

  const newAddon = () => {
    if(isNew){
      setIsNew(false)
      setAddonButtons(!addonButtons)
      setAddon(null)
      setMoveButton(false)
      setCursor("pointer")
    } else {
      setIsNew(true)
      setAddonButtons(!addonButtons)
      setPolygonButtons(false)
      setRouteButtons(false)
      setDrawButton(true)
      setCursor("crosshair")
    }
  }
  const newMarker = () => {
    if(isNew){
      setIsNew(false)
      setMarkerButtons(!markerButtons)
      setAddon(null)
      setMoveButton(false)
      setCursor("pointer")
    } else {
      setIsNew(true)
      setMarkerButtons(!markerButtons)
      setPolygonButtons(false)
      setRouteButtons(false)
      setDrawButton(true)
      setCursor("crosshair")
    }
  }
  const newRoute = () => {
    if(isNew){
      setIsNew(false)
      setRouteButtons(!routeButtons)
      setPoints([])
      setRoute(null)
      setDrawButton(false)
      setCursor("pointer")
    }else{
      setIsNew(true)
      setRouteButtons(!routeButtons)
      setAddonButtons(false)
      setMarkerButtons(false)
      setPolygonButtons(false)
      setDrawButton(true)
      setCursor("copy")
    }
    
  }

  const editRoute = () => {
    if(isEditing){
      setIsEditing(false)
      setPoints([])
      setRouteButtons(false)
      setRoute({id: route.id, point: lastPoints})
      setMoveButton(false)
      setDrawButton(false)
      setCursor("pointer")
    } else {
      setIsEditing(true)
      setRouteButtons(true)
      setPoints([...route.point])
      setLastPoints([...route.point])
      setRoute({id: route.id, point: null})
      setMoveButton(true)
      setDrawButton(false)
      setCursor("move")
    }
  }

  const editAddon = () => {
    if(isEditing){
      setIsEditing(false)
      setAddon([])
      setAddonEditing(null)
      setAddonButtons(false)
      setAddonSelected(null)
      setDrawButton(false)
      setMoveButton(false)
      setAddons([...lastAddons])
      setCursor("pointer")
    } else {
      setIsEditing(true)
      setAddonButtons(true)
      setLastAddons([...addons])
      setAddons([...addons.filter(el => el.id !== addonSelected.id)])
      setAddon(addonSelected.point[0])
      setAddonEditing(addonSelected)
      setAddonButtons(true)
      setAddonSelected(null)
      setDrawButton(false)
      setMoveButton(true)
      setCursor("move")
    }
  }

  const editMarker = () => {
    if(isEditing){
      setIsEditing(false)
      setAddon([])
      setAddonEditing(null)
      setMarkerButtons(false)
      setAddonSelected(null)
      setDrawButton(false)
      setMoveButton(false)
      setAddons([...lastAddons])
      setCursor("pointer")
    } else {
      setIsEditing(true)
      setMarkerButtons(true)
      setLastAddons([...addons])
      setAddons([...addons.filter(el => el.id !== addonSelected.id)])
      setAddon(addonSelected.point[0])
      setAddonEditing(addonSelected)
      setAddonSelected(null)
      setDrawButton(false)
      setMoveButton(true)
      setCursor("move")
    }
  }

  const openModal = (action) => {
    if(action === "savePolygon" && polygonEditing){
      setModalAction("putPolygon")
    } else if(action === "savePolygon"){
      setModalAction("postPolygon")
    } else if(action === "saveAddon" && addonEditing){
      setModalAction(type === "map" ? "putMarker" : "putAddon")
    } else if(action === "saveAddon"){
      setModalAction("postAddon")
    } else if(action === "saveMarker"){
      setModalAction("postMarker")
    } else if(action === "saveMap"){
      setModalAction("putMap")
    } else if(action === "settings"){
      setModalAction("settings")
    } else if(action === "saveRoute" && map.route !== null && map.route.length > 0){
      setModalAction("putRoute")
    } else if(action === "saveRoute" ){
      setModalAction("postRoute")
    }
    setModalActive(true)
  }

  const dataBuild = () => (
    {
      active: map.active,
      name: map.name,
      width: map.width,
      height: map.height,
      addons: addons === null ? [] : addons,
      polygons: polygons === null ? [] : polygons,
      id: map.id,
      route: route,
      motionGoal: map.motionGoal,
      description: map.description,
      image: map.image
  })
  

  const hideModalAndRefresh = () => {
    setModalActive(false)
    setSaveModal(false)
    setDisabledSave(true)
    getData()
  }

  const pxToPercent = (data) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const convert = JSON.stringify(PixelToPercent(dataCopy, canvasSize.w, canvasSize.h))
    return convert
  }

  const saveRoute = () => {
    const data = {
      mapId: map.id,
      point: pxToPercent(points)
    }
    const msgs = {
      post: t('map.postRoute'),
      put: t('map.putRoute'),
      del: t('map.delRoute')
    }
    changeMapRoute(data, msgs, route).then((response) => {
      if(response){
        setRoute({id: response.id, point: points})
      }else{
        setRoute(null)
      }
      setPoints([])
      setIsEditing(false)
      setRouteButtons(false)
      setMoveButton(false)
      setIsNew(false)
      setCursor("pointer")
    })
  }

  const updateAddonsState = (newAddon) => {
    newAddon.point = PercentToPixel(JSON.parse(newAddon.point), canvasSize.w, canvasSize.h)
    //("New",addons)
    setAddons([...(addons !== null && addons.length > 0 ? addons : []), newAddon])
    setModalActive(false)
    setSaveModal(false)
    setDisabledSave(true)
    setAddonButtons(false)
    setMarkerButtons(false)
    setMoveButton(false)
    setAddon(null)
    setPoints([])
    setIsNew(false)
    setCursor("pointer")
  }

  const delAddon = () => {
    const mode = type === "map" ? "marker" : "addon"
    deleteAddon(mode, addonSelected.id, t('map.successDEL'+mode)).then(() =>{
      setAddons([...addons.filter(addon => addon.id !== addonSelected.id)])
      setAddonSelected(null)
    })
  }

  const updatePolygonState = (polygon) => {
    const arrPoint = JSON.parse(polygon.point)
    polygon.points = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
      
    if(polygonEditing){
      //response is for PUT
      setPolygons([...polygons.filter(pol => pol.id !== polygon.id), polygon])
      setPolygonEditing(null)
      setAddonSelected(null)
    } else {
      //console.log("control", polygons)
      setPolygons([...polygons ? polygons : {}, polygon])
    }
    setPoints([])
    setModalActive(false)
    setSaveModal(false)
    setDisabledSave(true)
    setIsEditing(false)
    setPolygonButtons(false)
    setMoveButton(false)
    setDrawButton(false)
    setIsNew(false)
    setCursor("pointer")
  }

  const fillPolygon = (pol) => {
    //polygonSelected && polygonSelected.id === pol.id ? "rgb(71, 200, 242, 0.4)" : "rgb(0, 124, 193, 0.4)"
    if(polygonSelected && polygonSelected.id === pol.id){
      return "rgb(71, 200, 242, 0.4)"
    }else if(pol.active === 0){
      return "rgb(200, 200, 200, 0.6)"
    }else{
      return "rgb(0, 124, 193, 0.4)"
    }
  }

  useEffect(() => {
    if(canvasSize){
      const handleResize = () =>{
        if(canvasSize.w > canvasContainer.current.clientWidth || canvasSize.h > canvasContainer.current.clientHeight){
          alert(t('validation.screenSizeChange'))
        }
      }
      window.addEventListener('resize',handleResize)
    }
  },[canvasSize, canvasContainer])

  return (
    <>
    <div className={canvasStyle.container}>
      {map !== null &&
        <div className={canvasStyle.canvas} ref={canvasContainer}>
          <div 
          className={`${canvasStyle.background} ${canvasMode}`} 
          style={{
            backgroundImage: `url("data:image/png;base64,${map.image}")`,
            width: canvasSize.w,
            height: canvasSize.h,
            backgroundSize: backgroundSize,
            backgroundPosition: backgroundPosition
          }} 
          ref={canvas}
          >
            {canvasSize &&
              <Stage
              width={canvasSize.w}
              height={canvasSize.h}
              onClick={handleCanvasClick}
              onMouseMove={handleCanvasMouseMove}
              globalCompositeOperation="screen"
            >
              {/* LAYER FOR EXISTING POLYGONS AND ROUTE*/}
              <Layer>
                {/* paint the non-polygon areas */}
                {area &&
                  <Group>
                    <Rect
                      x={0}
                      y={0}
                      width={canvasSize.w}
                      height={canvasSize.h}
                      fill="rgb(255,255,255,0.9)"
                    />
                    <Line
                      points={area.reduce((a, b) => a.concat(b), [])}
                      fill="rgb(0,0,0)"
                      closed
                      globalCompositeOperation='destination-out'
                    />
                  </Group>
                }
                {polygons && polygons.map((pol, index) => {
                  return (
                    <Line
                      key={index}
                      points={pol.points.reduce((a, b) => a.concat(b), [])}
                      stroke="rgb(0, 88, 163)"
                      fill={fillPolygon(pol)}
                      strokeWidth={polygonSelected && polygonSelected.id === pol.id ? 4 : 2}
                      closed
                      onPointerClick={()=> !moveButton && !drawButton && clickPolygon(pol)}
                    />
                  )
                })}
                {route && route.point &&
                  route.point.map((point, index) => {
                    if(route.point.length !== index+1){
                      const fragment = [point[0],point[1],route.point[index+1][0],route.point[index+1][1]]
                      return(
                        <Arrow
                        key={index} 
                        points={fragment}
                        pointerLength={6}
                        pointerWidth={6}
                        fill='rgb(255, 255, 255)'
                        stroke="rgb(10, 138, 0)"
                        strokeWidth={1.5}
                        dash={[15, 5]}
                        />
                      )
                    }
                    return false;
                    }
                  )
                }
              </Layer>

              {/* LAYER FOR EXISTING ADDONS*/}
              <Layer>
                {addons &&
                  addons.map((addon, index) => 
                  <Path
                  key={index}
                  x={addon.point[0][0] - 20} //20 is aprox the middle size of icon
                  y={addon.point[0][1] - 20}
                  data={addon.icon}
                  fill= {addonSelected && addonSelected.id === addon.id ? "rgb(204, 0, 8, 0.4)" : "rgb(204, 0, 8)"}
                  stroke={addonSelected && addonSelected.id === addon.id ? "rgb(204, 0, 8)" : ""}
                  strokeWidth={1}
                  scaleX= {1.5}
                  scaleY= {1.5}
                  onPointerClick={()=> !moveButton && !drawButton && clickAddon(addon)}
                  fillRule="evenodd"
                  />
                  )
                }
              </Layer>

              {/* LAYER FOR DRAW NEW POLYGONS, ADDONS AND NEW ROUTES*/}
              <Layer >
                <Line
                  points={flattenedPoints}
                  stroke="black"
                  fill="rgb(255,246,155,0.4)"
                  strokeWidth={2}
                  closed={polygonButtons && isPolygon}
                />
                {points.map((point, index) => {
                  return (
                    <Circle
                    key={index}
                    x={point[0]} 
                    y={point[1]} 
                    radius={4} 
                    fill="white"
                    stroke="black"
                    strokeWidth={2}
                    onDragMove={handleDragMovePoint}
                    draggable
                    />
                  )
                })}
                {addon && 
                  <Circle
                  x={addon[0]} 
                  y={addon[1]} 
                  radius={14} 
                  fill="rgb(242, 106, 47, 0.3)"
                  stroke="rgb(242, 106, 47)"
                  strokeWidth={2}
                  onDragMove={handleDragMoveAddon}
                  draggable
                  />
                }
              </Layer>
            </Stage>
            }
          </div>
        </div>
      }
      <div className={canvasStyle.console}>
        {!polygonSelected && !addonSelected &&
        <div className={canvasStyle.polygonButtons}>
          {/* POLYGON */}
          <Button 
          text={t("map.newPolygon")}
          type={polygonButtons ? "primary" :"emphasised"} 
          ssrIcon={space}
          small
          onClick={isEditing ? editPolygon :newPolygon}
          name="polygon"
          />
          {polygonButtons &&
          <div id="polygonButtons">
            <Button 
            text={t("map.draw")}
            type={drawButton ? "primary" : "secondary"} 
            ssrIcon={draw}
            small
            onClick={() => {
              setDrawButton(!drawButton)
              setMoveButton(false)
              setCursor("copy")
            }}
            />
            <Button 
            text={t("map.move")}
            type={moveButton ? "primary" : "secondary"} 
            ssrIcon={move}
            small
            onClick={() => {
              setMoveButton(!moveButton)
              setDrawButton(false)
              setCursor("move")
            }}
            />
            <Button 
            text={t("global.delete")}
            type="secondary" 
            ssrIcon={del}
            small
            onClick={deletePoints}
            />
            {isPolygon &&
              <Button 
              text={t("global.save")}
              type="secondary" 
              ssrIcon={save}
              small
              onClick={() => openModal("savePolygon")}
              />
            }
          </div>
          }

          {/* MARKER */}
          <Button 
          text={t("global.marker")}
          type={markerButtons ? "primary" :"emphasised"} 
          ssrIcon={markerIcon}
          small
          onClick={isEditing ? editMarker :newMarker}
          name="marker"
          />
          {markerButtons &&
            <div id="addonButtons">
              <Button 
              text={t("map.move")}
              type={moveButton ? "primary" : "secondary"} 
              ssrIcon={move}
              small
              disabled={addon === null}
              onClick={() => {
                setMoveButton(true)
                setDrawButton(false)
                setCursor("move")
              }}
              />
              <Button 
              text={t("global.save")}
              type="secondary" 
              ssrIcon={save}
              small
              disabled={addon === null}
              onClick={() => openModal("saveMarker")}
              />
            </div>
            }

          {/* ADDON */}  
          {type !== "map" &&
          <>
            <Button 
            text={t("global.addon")}
            type={addonButtons ? "primary" :"emphasised"} 
            ssrIcon={addonIcon}
            small
            onClick={isEditing ? editAddon :newAddon}
            id="addon"
            />
            {addonButtons &&
              <div id="addonButtons">
                <Button 
                text={t("map.move")}
                type={moveButton ? "primary" : "secondary"} 
                ssrIcon={move}
                small
                disabled={addon === null}
                onClick={() => {
                  setMoveButton(true)
                  setDrawButton(false)
                  setCursor("move")
                }}
                />
                <Button 
                text={t("global.save")}
                type="secondary" 
                ssrIcon={save}
                small
                disabled={addon === null}
                onClick={() => openModal("saveAddon")}
                />
              </div>
              }
            </>
            }
            

            {/* ROUTE */}
            {type === "map" &&
            <>
              <Button 
              text={route ? t("global.edit")+" "+t("map.officialRoute") : t("map.officialRoute")}
              type={routeButtons ? "primary" : (route ? "secondary" : "emphasised")} 
              ssrIcon={routeIcon}
              small
              onClick={route ? editRoute : (isEditing ? editRoute : newRoute)}
              name="route"
              />
              {routeButtons &&
                <div id="routeButtons">
                  {map.route === null &&
                    <Button 
                    text={t("map.draw")}
                    type={drawButton ? "primary" : "secondary"} 
                    ssrIcon={draw}
                    small
                    onClick={() => {
                      setDrawButton(!drawButton)
                      setMoveButton(false)
                      setCursor("copy")
                    }}
                    />
                  }
                  
                  <Button 
                  text={t("map.move")}
                  type={moveButton ? "primary" : "secondary"} 
                  ssrIcon={move}
                  small
                  onClick={() => {
                    setMoveButton(!moveButton)
                    setDrawButton(false)
                    setCursor("move")
                  }}
                  />
                  <Button 
                  text={t("global.delete")}
                  type="secondary" 
                  ssrIcon={del}
                  small
                  onClick={deletePoints}
                  />
                  <Button 
                  text={t("global.save")}
                  type="secondary" 
                  ssrIcon={save}
                  small
                  onClick={saveRoute}
                  />
                </div>
              }
            </>
            }
        </div>
        }
        
        {/* OPTIONS SELECTED */}
        {(polygonSelected || addonSelected) &&
        <div id="editButtons" className={canvasStyle.editButtons}>
          <TextComponent tagName="h4" headingSize="s">
            {polygonSelected ? polygonSelected.type.label + (polygonSelected.subType ? " - " + polygonSelected.subType.label  : "") : addonSelected.type.label}
          </TextComponent>
          {polygonSelected && type === "map" &&
            <Button 
            text={t("global.enter")}
            type="secondary" 
            ssrIcon={enter}
            small
            onClick={goToPolygon}
            />
          }
          <Button 
          text={t("global.edit")}
          type="secondary" 
          ssrIcon={edit}
          small
          onClick={polygonSelected ? editPolygon : (addonSelected.isMarker ? editMarker : editAddon)}
          />
          {addonSelected &&
            <Button 
            text={t("global.delete")}
            type="secondary" 
            ssrIcon={del}
            small
            onClick={delAddon}
            />
          }
        </div>
        }
        
        {/* MAP OPTIONS */}
        <div id="optionsButtons">
          {type === "map" &&
            <Button 
            text={t("global.edit")+" "+t(`global.${type}`)}
            type="secondary" 
            ssrIcon={mapIcon}
            small
            onClick={() => openModal("saveMap")}
            id="edit"
            />
          }
          
          <Button 
          text={t("global.settings")}
          type="secondary" 
          ssrIcon={setting}
          small
          onClick={() => openModal("settings")}
          name="settingButton"
          />
        </div>

      </div>
    </div>
    <Modal
    visible={modalActive}
    handleCloseBtn={()=> {
      setModalActive(!modalActive)
      setSaveModal(false)
      setDisabledSave(true)
      }}>
      <Sheets
        aria-label="Accessibility header for a modal"
        className="example-prompt-override"
        footer={modalAction !== "settings" &&
          <ModalFooter>
            <Button text={t("global.save")} 
              type="emphasised" 
              onClick={() => setSaveModal(true)} 
              disabled={disabledSave}/>
            <Button text={t("global.cancel")} />
          </ModalFooter>
          }
        header={<ModalHeader ariaCloseTxt="Close button text" title={t(`map.${modalAction}Title`)}/>}
      >
        <div className="container">
        {(modalAction === "putPolygon" || modalAction === "postPolygon") &&
          <AddPolygon
          disabledSave={(dis) => setDisabledSave(dis)}
          save={saveModal}
          type={modalAction === "putPolygon" ? "PUT" : "POST"}
          mode={type === "map" ? "section" : "subsection"}
          points={points}
          polygon={polygonEditing ? polygonEditing : {}}
          canvasSize={canvasSize}
          mapId={type === "map" ? map.id : map.map_id}
          parentId={map.id}
          updatePolygonState={updatePolygonState}
          fatherValue={map.subType ? map.subType.value : map.storeId }
          />
        }
        {(modalAction === "postAddon" || modalAction === "postMarker" || modalAction === "putMarker" || modalAction === "putAddon") &&
          <AddAddon
          disabledSave={(dis) => setDisabledSave(dis)}
          save={saveModal}
          type={addonEditing ? "PUT" : "POST"}
          points={addon ? pxToPercent([addon]) : null}
          mode={modalAction === "postMarker" || modalAction === "putMarker" ? "marker" : "addon"}
          mapId={map.id}
          updateAddonsState={updateAddonsState}
          addonEdit={addonEditing}
          level={level}
          />
        }
      
        {modalAction === "settings" &&
          <Settings canvasMode={true}/>
        }
        
        {modalAction === "putMap" &&
          <AddMap
          disabledSave={(dis) => setDisabledSave(dis)}
          save={saveModal}
          type="put"
          map={dataBuild()}
          hideModalAndRefresh={hideModalAndRefresh}
          />
        }
        
        </div>
      </Sheets>
    </Modal>
  </>
  )
}
