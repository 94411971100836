import React, { useEffect, useState } from 'react'
import Text from '../components/ingka/Text'
import { useTranslation } from 'react-i18next'
import Select from "../components/global/Select"
import { getAllUsers, getRoles, saveUserRol } from '../services/userServices'
import Button from '../components/ingka/Button'
import { useDispatch, useSelector } from 'react-redux'
import { createMsgToast } from '../store/globalSlice'
import Table from '../components/global/Table'
import Load from '../components/ingka/Load'

export default function Users() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()

  const [user, setUser] = useState(null)
  const [userOptions, setUserOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [rol, setRol] = useState(null)
  const [rolOptions, setRolOptions] = useState(null)
  const [admins, setAdmins] = useState(null)

  const roleUser = useSelector((state) => state.global.rol)
  const myStore = useSelector((state) => state.global.myStore)
  const [columns, setColumns] = useState(null)

  useEffect(() => {
    getUsers()
  },[])

  useEffect(() => {
    if(user){
      getRoles(user.roles).then(roles => {
        setRolOptions(roles)
        if(user.roles.length > 0){
          setRol(roles.find(rol => rol.value === user.roles[0].id))
        }else{
          setRol(null)
        }
      })
    }
  }, [user])

  const getUsers = () => {
    getAllUsers().then(users => {
      setUserOptions(users)
      if(roleUser.name === "SUPERADMIN"){
        //SUPERADMIN TABLE
        setAdmins(users.filter(user => user.roles.length > 0 && user.roles[0].name !== "COWORKER"))
        setColumns([
          {accessorKey: "displayName", header: t('global.name')},
          {accessorKey: "mail", header: 'Email'},
          {accessorKey: "roleName", header: t('global.rol')},
          {accessorKey: "officeLocation", header: "STO"}
        ])
      } else {
        //ADMIN TABLE
        setAdmins(users.filter(user => user.roles.length > 0 && user.roles[0].name === "ADMIN" && user.officeLocation === myStore.officeLocation))
        setColumns([
          {accessorKey: "displayName", header: t('global.name')},
          {accessorKey: "mail", header: 'Email'},
          {accessorKey: "roleName", header: t('global.rol')}
        ])
      }
    }).finally(() => setLoading(false))
  }

  const saveUser = () => {
    setLoading(true)
    const data = {
      employedId: user.value,
      rolId: rol.value
    }
    saveUserRol(data).then(()=> {
      getUsers()
      dispatch(createMsgToast({body: t("users.successSave"), variant:"positive"}))
      setUser(null)
      setRol(null)
    })
  }

  return (
    <div>
      <Text tagName="h2" headingSize="m">{t(`menu.configuration.users`)}</Text>
      <div>
        {loading && 
          <Load />
        }
        {columns && admins && !loading &&
          <>
          <div className="row">
            <Select
              formField={{className:"col-lg-6 col-md-6 col-sm-12"}}
              label={t("global.user")}
              onChange={(val) => {
                setUser(val)
              }}
              isClearable={false}
              isSearchable={true}
              name="user"
              options={userOptions}
              value={user}
            />
            {user && rolOptions &&
              <Select
              formField={{className:"col-lg-4 col-md-4 col-sm-12"}}
              label={t("global.rol")}
              onChange={(val) => {
                setRol(val)
              }}
              isClearable={false}
              isSearchable={false}
              name="rol"
              options={rolOptions}
              value={rol}
            />
            }
            
            <Button
              type="emphasised"
              text={t('global.save')}

              onClick={saveUser}
              className="col-lg-2 col-md-2 col-sm-12"
              disabled={rol === null || user === null}
            />
          </div>
        
          <Table 
            columns={columns} 
            data={admins}
            enableSorting={true}
            enablePagination={false}
          />
        </>
        }
      </div>
    </div>
  )
}
