import mapImage from "../assets/img/level-map-thumbnail.webp"
import sectionImage from "../assets/img/level-section-thumbnail.webp"
import subsectionImage from "../assets/img/level-subsection-thumbnail.webp"

export const levelsForMotion = [
    {
      id:1,//map
      level: 0,
      img: mapImage,
      title: "dashboard.completeArea",
    },
    {
      id:2,//section
      level: 1, 
      img: sectionImage,
      title: "dashboard.sectionArea"
    },
    {
      id:3, //subsection
      level: 2,
      img: subsectionImage,
      title: "dashboard.subSectionArea"
    }
]