import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/modal/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/carousel/style.scss";
import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import { OverflowCarousel } from "@ingka/carousel";
import Button from "../ingka/Button";
import { useEffect, useState } from "react";
import filterIcon from '@ingka/ssr-icon/paths/filters'
import { useTranslation } from "react-i18next";
import style from "./filter.module.scss"
import { useSelector } from "react-redux";
import Select from "./Select";
import { getStoreMaps, getStoreOptions } from "../../services/viewingMotionsServices";
import { getMap, getSection } from "../../services/mapServices";
import { LoadingBall } from "@ingka/loading";
import DatePicker from "./DatePicker";
import { Controller, useForm } from "react-hook-form";
import { getSaveOptions } from "../../services/motionServices";
import { getListActions } from "../../services/settingsServices";

export default function Filter({pushQuery, pushOptions}) {
  const[modalOpen, setModalOpen] = useState(true)
  const [t] = useTranslation("global")
  const { handleSubmit, control, reset } = useForm();

  const myStore = useSelector((state) => state.global.myStore)
  const rol = useSelector((state) => state.global.rol)
  const maps = useSelector((state) => state.canvas.maps)
  const [filters, setFilters] = useState(null)
  const [loading, setLoading] = useState(false)


  const[store, setStore] = useState(null)
  const[storeOptions, setStoreOptions] = useState(null)
  const[map, setMap] = useState(null)
  const[mapOptions, setMapOptions] = useState(null)
  const[section, setSection] = useState(null)
  const[sectionOptions, setSectionOptions] = useState(null)
  const[subsection, setSubsection] = useState(null)
  const[subsectionOptions, setSubsectionOptions] = useState(null)
  const[from, setFrom] = useState("")
  const[to, setTo] = useState("")
  const [group, setGroup] = useState(null)
  const [age, setAge] = useState(null)
  const [gender, setGender] = useState(null)
  const [nationality, setNationality] = useState(null)
  const [tools, setTools] = useState(null)
  const [groupOptions, setGroupOptions] = useState([])
  const [ageOptions, setAgeOptions] = useState([])
  const [genderOptions, setGenderOptions] = useState([])
  const [nationalityOptions, setNationalityOptions] = useState([])
  const [toolsOptions, setToolsOptions] = useState([])
  const [actions, setActions] = useState(null)
  const [versionSection, setVersionSection] = useState(null)
  const [versionSectionOptions, setVersionSectionOptions] = useState(null)
  const [versionSubsection, setVersionSubsection] = useState(null)
  const [versionSubsectionOptions, setVersionSubsectionOptions] = useState(null)

  useEffect(() => {
    const date = new Date()
    const today = date.toISOString().slice(0, 10)
    date.setMonth(date.getMonth()-1)
    const lastMonth = date.toISOString().slice(0, 10)
    setFrom(lastMonth)
    setTo(today)
    reset({ fromData: lastMonth, toData: today})

    getSaveOptions().then(data => {
      setGroupOptions(data.demographicGroups)
      setAgeOptions(data.ageRanges)
      setGenderOptions(data.genders)
      setNationalityOptions(data.nationalities)
      setToolsOptions(data.tools)
    })
    getListActions().then( actions => setActions(actions))
  },[])

  useEffect(()=>{
    setMapOptions(maps.map(m => ({value: m.id, label: m.name})))
  },[maps])

  useEffect(() => {
    setStore({value:myStore.id, label:myStore.name})
  },[myStore])

  const changeStore = (store) => {
    setLoading(true)
    setMap(null)
    setSection(null)
    setSubsection(null)
    setMapOptions(null)
    setSectionOptions(null)
    setSubsectionOptions(null)

    getStoreMaps(store.value).then((options)=> {
      setMapOptions(options)
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    if(rol.name === "SUPERADMIN"){
      setLoading(true)
      getStoreOptions().then(stores => setStoreOptions(stores)).finally(setLoading(false))
    }
  },[rol])
  
  useEffect(() => {
    if(map){
      setLoading(true)
      setSection(null)
      setSectionOptions(null)
      setVersionSectionOptions(null)
      getMap({mapId: map.value})
      .then(response => {
        if(response.polygons.length > 0){
          setSectionOptions(response.polygons.map(pol => ({
            value: pol.id, 
            label:pol.type.label + (pol.subType ? " - "+pol.subType.label : ""), 
            versions: pol.versions, 
            currentVersion:pol.modificationDate ? pol.modificationDate : pol.creationDate
          })))
        }
      }).finally(() => setLoading(false))
    }
  },[map])

  useEffect(() => {
    if(section){
      setSubsection(null)
      setSubsectionOptions(null)
      setVersionSubsection(null)
      setVersionSubsectionOptions(null)
      //console.log(section)
      if(section.versions.length > 0){
        let optionsVersionSection = []
        optionsVersionSection.push({value: section.currentVersion, label:t('components.filter.currentVersion')})
        section.versions.forEach( ver => {
          optionsVersionSection.push({value: ver.date, label:ver.date.replace("T", " ").slice(0,19)})
        })
        //console.log("Version",optionsVersionSection)
        setVersionSectionOptions(optionsVersionSection)
        setVersionSection(optionsVersionSection[0]) //default actual version
      }else{
        setVersionSection(section.currentVersion)
      }
    }
    setLoading(false)
  },[section])

  useEffect(() => {
    if(versionSection){
      setLoading(true)
      setSubsection(null)
      setSubsectionOptions(null)
      getSection(section.value)
      .then(response => {
        if(response.polygons.length > 0){
          setSubsectionOptions(response.polygons.map(pol => ({
            value: pol.id, 
            label:pol.type.label + (pol.subType ? " - "+pol.subType.label : ""),
            versions: pol.versions, 
            currentVersion:pol.modificationDate ? pol.modificationDate : pol.creationDate
          })))
        }
      })
      .finally(()=>setLoading(false))
    }
  },[versionSection])

  useEffect(() => {
    if(subsection){
      setVersionSubsection(null)
      setVersionSubsectionOptions(null)
      //console.log(subsection)
      if(subsection.versions.length > 0){
        let optionsVersionSubsection = []
        optionsVersionSubsection.push({value: subsection.currentVersion, label:t('components.filter.currentVersion')})
        subsection.versions.forEach( ver => {
          optionsVersionSubsection.push({value: ver.date, label:ver.date.replace("T", " ").slice(0,19)})
        })
        //console.log("Version",optionsVersionSubsection)
        setVersionSubsectionOptions(optionsVersionSubsection)
        setVersionSubsection(optionsVersionSubsection[0]) //default actual version
      } else {
        setVersionSubsection(subsection.currentVersion)
      }
    }
    setLoading(false)
  },[subsection])

  const applyFilter = () => {
    const filtersArray = [
      {...store, title: "STO:"},
      {...map, title: `${t('global.map')}:`},
      {value:from, label:from, title: `${t('global.from')}:`},
      {value:to, label:to, title: `${t('global.to')}:`},
    ]
    if(section) filtersArray.push({...section, title: `${t('global.section')}:`})
    if(subsection) filtersArray.push({...subsection, title: `${t('global.subsection')}:`})
    if(group) filtersArray.push({...group, label: group.map(el => (el.label)), title: `${t('global.groups')}:`})
    if(age) filtersArray.push({...age, label: age.map(el => (el.label)), title: `${t('global.ages')}:`})
    if(gender) filtersArray.push({...gender, label: gender.map(el => (el.label)), title: `${t('global.genders')}:`})
    if(nationality) filtersArray.push({...nationality, label: nationality.map(el => (el.label)), title: `${t('global.nationalities')}:`})
    if(tools) filtersArray.push({...tools, label: tools.map(el => (el.label)), title: `${t('global.tools')}:`})
    //console.log(filtersArray)
    setFilters(filtersArray)
    setModalOpen(!modalOpen)
    //console.log(versionSection)
    const query = {
      storeId: store.value,
      mapId: map.value,
      startDate: from,
      endDate: to,
      level: subsection ? 2 : (section ? 1 : 0),
      ...(section ? {sectionId: section.value} : {}),
      ...(subsection ? {subsectionId: subsection.value} : {}),
      ...(group ? {group: group.map(el => (el.value)).toString()} : {}),
      ...(age ? {ageRange: age.map(el => (el.value)).toString()} : {}),
      ...(gender ? {genders: gender.map(el => (el.value)).toString()} : {}),
      ...(nationality ? {nationalities: nationality.map(el => (el.value)).toString()} : {}),
      ...(tools ? {tools: tools.map(el => (el.value)).toString()} : {}),
      ...(section ? {versionId: subsection ? versionSubsection.value : versionSection.value} : {}),
    }
    pushQuery(query)
    pushOptions({
      actions: actions, 
      groupOptions:groupOptions, 
      ageOptions: ageOptions, 
      genderOptions: genderOptions, 
      nationalityOptions: nationalityOptions, 
      toolsOptions: toolsOptions
    })
  }
  
  const resetFilters = () => {
    const today = new Date().toISOString().slice(0, 10)
    setFrom(today)
    setTo(today)
    reset({ fromData: today, toData: today})
    setStore({value:myStore.id, label:myStore.name})
    setMap(null)
    setSection(null)
    setSubsection(null)
    setGroup(null)
    setAge(null)
    setGender(null)
    setNationality(null)
    setTools(null)
  }

  return (
    <>
      <div className={style.containerFilters}>    
        <div className={style.filterButtons}>
          <OverflowCarousel
          controlBtnType="primary"
          id="demo">
            <Button 
              onClick={() => setModalOpen(!modalOpen)}
              type="tertiary"
              iconOnly
              ssrIcon={filterIcon}
              small
            />
            {filters === null &&
              <Button 
                type="tertiary"
                text={t('components.filter.noFilters')}
                small
                className={style.noFilter}
                onClick={()=>setModalOpen(!modalOpen)}
              />
            }
            {filters &&
              filters.map((filter, index) => (
                <Button
                key={index}
                type="tertiary"
                text={filter.title + filter.label}
                small
                className={style.filterButton}
                onClick={()=>setModalOpen(!modalOpen)}
                />
              ))
              
            }
          </OverflowCarousel>
          <hr/>
        </div>
      </div>
      <Modal
        visible={modalOpen}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalOpen(!modalOpen)}
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className={style.modal_header} title={t("components.filter.title")}/>}
        >
          <>
          {loading &&
          <div className={style.loading}>
            <LoadingBall />
          </div>
          }
          </>
          <ModalBody className={style.modal_body}>
            <form id="filterForm" onSubmit={handleSubmit(applyFilter)} className="row">
              <div className={style.reset}>
                <Button
                  type="tertiary"
                  text={t('components.filter.clean')}
                  small
                  className={style.filterButton}
                  onClick={() => resetFilters()} 
                />
              </div>

              {/* STORE SELECTOR */}
              {rol.name === "SUPERADMIN" &&
                <Select
                formField={{className:"col-12"}}
                label={t('global.select') + ' store'}
                onChange={val => {
                  setStore(val)
                  changeStore(val)
                }}
                defaultValue={store}
                value={store}
                isClearable={false}
                isSearchable={true}
                id="store"
                name="store"
                options={storeOptions}
              />
              }
              

              {/* MAP SELECTOR */}
              <Controller
                control={control}
                name="map"
                rules={{required: true }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-contributor",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={t('global.select') + ' ' + t('global.map').toLowerCase()}
                  value={map}
                  onChange={val => {
                    onChange(val)
                    setMap(val)
                  }}
                  inputRef={ref}
                  isClearable={false}
                  isSearchable={true}
                  id="map"
                  name="map"
                  options={mapOptions}
                />
                )} 
              />

              {/* SECTION SELECTOR */}
              {map && sectionOptions &&
              <Select
                formField={{className:"col-12"}}
                label={t('global.select') + ' ' + t('global.section').toLowerCase()}
                onChange={val => setSection(val)}
                value={section}
                isClearable={false}
                isSearchable={false}
                id="section"
                name="section"
                options={sectionOptions}
              />
              }

              {/* SECTION VERSION SELECTOR */}
              {versionSectionOptions && section && subsection === null &&
              <Select
                formField={{className:"col-12"}}
                label={t('global.select') + ' ' + t('global.version').toLowerCase()}
                onChange={val => setVersionSection(val)}
                value={versionSection}
                isClearable={false}
                isSearchable={false}
                id="versionSection"
                name="versionSection"
                options={versionSectionOptions}
              />
              }

              {/* SUBSECTION SELECTOR */}
              {versionSection && subsectionOptions &&
              <Select
                formField={{className:"col-12"}}
                label={t('global.select') + ' ' + t('global.subsection').toLowerCase()}
                onChange={val => setSubsection(val)}
                defaultValue={subsection}
                value={subsection}
                isClearable={false}
                isSearchable={false}
                id="subsection"
                name="subsection"
                options={subsectionOptions}
              />
              }

              {/* SUBSECTION VERSION SELECTOR */}
              {subsection && versionSubsectionOptions &&
              <Select
                formField={{className:"col-12"}}
                label={t('global.select') + ' ' + t('global.version').toLowerCase()}
                onChange={val => setVersionSubsection(val)}
                defaultValue={versionSubsection}
                value={versionSubsection}
                isClearable={false}
                isSearchable={false}
                id="versionSubsection"
                name="versionSubsection"
                options={versionSubsectionOptions}
              />
              }

              {/* DATE SELECTOR */}
              <Controller
                control={control}
                name="fromData"
                rules={{required: true }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                  <DatePicker
                  formField={{
                    className:"col-6",
                    shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-contributor",
                        msg:t("validation.required"),
                        type: "error"
                      }
                  }}
                  label={t('global.from')}
                  value={from}
                  onChange={(e) => {
                    onChange(e.target.value)
                    setFrom(e.target.value)
                  }}
                  inputRef={ref}
                />
                )} 
              />
              <Controller
                control={control}
                name="toData"
                rules={{required: true }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                  <DatePicker
                  formField={{
                    className:"col-6",
                    shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-contributor",
                        msg:t("validation.required"),
                        type: "error"
                      }
                  }}
                  label={t('global.to')}
                  value={to}
                  onChange={(e) => {
                    onChange(e.target.value)
                    setTo(e.target.value)
                  }}
                  inputRef={ref}
                />
                )} 
              />

              {/* MOTION OPTIONS */}
              <Select
                formField={{className:"col-12"}}
                label={t("motion.demographicGroup")}
                onChange={(val) => {
                  setGroup(val)
                }}
                isClearable={true}
                isMulti={true}
                id="demographic_group"
                name="demographic group"
                options={groupOptions}
                value={group}
              />
              
              <Select
                formField={{className:"col-12"}}
                label={t("motion.ageRange")}
                onChange={(val) => {
                  setAge(val)
                }}
                isClearable={true}
                isMulti={true}
                id="age_range"
                name="age range"
                options={ageOptions}
                value={age}
              />

              <Select
                formField={{className:"col-12"}}
                label={t("motion.gender")}
                onChange={(val) => {
                  setGender(val)
                }}
                isClearable={true}
                isMulti={true}
                id="gender"
                name="gender"
                options={genderOptions}
                value={gender}
              />

              <Select
                formField={{className:"col-12"}}
                label={t("motion.nationality")}
                onChange={(val) => {
                  setNationality(val)
                }}
                isClearable={true}
                isMulti={true}
                id="nationality"
                name="nationality"
                options={nationalityOptions}
                value={nationality}
              />

              <Select
                formField={{className:"col-12"}}
                label={t("motion.shoppingTools")}
                onChange={(val) => {
                  setTools(val)
                }}
                isClearable={true}
                isMulti={true}
                id="shooping_tools"
                name="shooping tools"
                options={toolsOptions}
                value={tools}
              />
            </form>
          </ModalBody>
          <div className={style.submit}>
            <Button
              type="emphasised"
              text={t("global.filter")}
              htmlType="submit"
              disabled={loading}
              form="filterForm"
            />
          </div>
          
        </Sheets>
      </Modal>
    </>
  )
}