import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "./Select";
import { getIkeaProducts, getMarkers, saveAddon } from "../../services/mapServices";
import { useDispatch } from "react-redux";
import { createMsgToast } from "../../store/globalSlice";
import Load from "../ingka/Load";
import SearchBar from "../ingka/SearchBar";
import Text from "../ingka/Text";

export default function AddAddon({type, addonEdit, save, disabledSave, mode, points, mapId, updateAddonsState, level}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()

  const productIcon = "m12 2 9 4.989v10.022L12 22l-9-4.989V6.989L12 2zm0 2.2614-5.9603 3.304L12 10.8692l5.9603-3.304L12 4.2613zM5 15.8583v-6.608l6 3.326v6.608l-6-3.326zm8 3.326 6-3.326v-6.608l-6 3.326v6.608z"

  const [loading, setLoading] = useState(false)
  const [addon, setAddon] = useState(null)
  const [addons, setAddons] = useState(null)
  const [ref, setRef] = useState(null)
  const [validateRef, setValidateRef] = useState(true)
  
  useEffect(()=>{
    if(addons === null){
      setLoading(true)
      if(mode === "marker"){
        getMarkers(mode, level).then((addons) => {
          setAddons(addons)
          if(type === "PUT"){
            const theAddon = addons.find(el => el.value === addonEdit.type.value)
            setAddon(theAddon)
          }
        }).finally(() => setLoading(false))
      }else{
        if(type === "PUT"){
          setAddon({
            icon: productIcon,
            value: addonEdit.type.value,
            label: addonEdit.type.label
          })
        }
        setLoading(false)
      }
    }
  },[])

  useEffect(() =>{
    if(addon){
      disabledSave(false)
    }
  },[addon])

  useEffect(() => {
    if(ref){
      if(ref.length === 8){
        setValidateRef(null)
        getIkeaProducts(ref).then((addons) => {
          setAddon({
            icon: productIcon,
            value: addons.data[0].itemKey.itemNo,
            label: addons.data[0].productNameGlobal
          })
        }).finally(() => setLoading(false))
      }else{
        //ref is not 8 length
        setValidateRef(false)
      }
    }
    
    
  },[ref])

  useEffect(() => {
    if(save){
      saveAddon(type, mode, addon, addonEdit, points, mapId, level).then((response) => {
        dispatch(createMsgToast({body: t("map.success"+type+mode), variant:"positive"}))
        updateAddonsState(response)
      })
    }
  },[save])

  return (
    <div style={{minHeight: 300}}>
      {loading && 
        <Load />
      }
      {!loading &&
      <>
      {mode === "marker" &&
        <Select 
          formField={{className:"col-12"}}
          label={t("map.whatAdd")}
          defaultValue={addon}
          onChange={(val) => setAddon(val)}
          isClearable={false}
          isSearchable={false}
          id="type"
          name="type"
          options={addons}
        />
      }
      {mode === "addon" &&
      <>
        <SearchBar
          ariaLabel="Search for product categories, product names, etc."
          placeholder={t("map.searchByRef")}
          clearBtnText={t("global.delete")}
          id="search"
          onSearch={(e, data) =>setRef(data.value)}
          submitBtnText={t('global.search')}
        />
        {validateRef !== null && 
          <Text tagName="p" bodySize="s" className={!validateRef ? "text-red" : ""}>
            La referencia ha de tener 8 caracteres
            Ejemplpos: 10458098, 40457988, 60458581
          </Text>
        }
        {addon &&
          <>
            <Text tagName="h3" bodySize="xl" className="mb-0">{addon.label}</Text>
            <Text tagName="p" bodySize="m" className="mt-0">REF: {addon.value}</Text>
          </>
        }
        
        </>
      }
        
      </>
    }
    </div>
  )
}
