import { useTranslation } from "react-i18next"
import Text from "../components/ingka/Text"
import Message from "../components/ingka/Message"
import style from "../assets/scss/mapMessagge.module.scss"
import arrowR from '@ingka/ssr-icon/paths/arrow-right'
import pencil from '@ingka/ssr-icon/paths/pencil'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createRoute } from "../store/globalSlice"
import { useEffect, useState } from "react"
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import Button from "../components/ingka/Button"
import AddMap from "../components/global/AddMap"
import { setSelectionRange } from "@testing-library/user-event/dist/utils"

export default function Maps() {
  const [t] = useTranslation("global")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const maps = useSelector((state) => state.canvas.maps)
  const [modalActive, setModalActive] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const [disabledSave, setDisabledSave] = useState(true)
  const [mapSelected, setMapSelected] = useState(null)

  const goToMap = (map) => {
    navigate(`/map/${map.id}`)
    dispatch(createRoute({id:map.id, path:`/map/${map.id}`, back:"/maps"}))
  }

  const openModal = (map) => {
    setModalActive(!modalActive)
    setMapSelected(map)
  }

  const hideModalAndRefresh = () => {
    setModalActive(false)
    setSaveModal(false)
    setDisabledSave(true)
  }

  return (
    <div>
      <Text tagName="h2" headingSize="m">{t(`maps.title`)}</Text>
      {maps &&
        maps.map(map => 
          <Message 
            key={map.id}
            title={
              <div className={style.mapCard}>
                {map.image &&
                  <div className={style.image} style={{ backgroundImage: `url("data:image/png;base64,${map.image}")`}}></div>
                }
                
                <div className={style.content}>
                  <Text tagName="h3" headingSize="s">{map.name}</Text>
                  <Text tagName="p" bodySize="m">{map.description}</Text>
                  <div className={style.data}>
                    <Text tagName="p" bodySize="s">{map.creationDate.slice(0,10)}</Text>
                  </div>
                </div>
              </div>
            }
            variant={map.active === 0 ? "cautionary" : "informative"}
            actions={[
              map.image !== null ? {
                onClick: ()=>goToMap(map),
                iconOnly: true,
                ssrIcon: arrowR,
                className:"goToButton"
              } : 
              {
                onClick: ()=> openModal(map),
                iconOnly: true,
                ssrIcon: pencil,
                className:"goToButton"
              },
            ]}
          />
        )
      }
      <Modal
      visible={modalActive}
      handleCloseBtn={()=> {
        setModalActive(!modalActive)
        }}>
        <Sheets
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button text={t("global.save")} 
                type="emphasised" 
                onClick={() => setSaveModal(true)} 
                disabled={disabledSave}/>
              <Button text={t("global.cancel")} />
            </ModalFooter>
            }
          header={<ModalHeader ariaCloseTxt="Close button text" title={t(`map.putMapTitle`)}/>}
        >
          <div className="container">
          
            <AddMap
              disabledSave={(dis) => setDisabledSave(dis)}
              save={saveModal}
              type="put"
              map={mapSelected}
              hideModalAndRefresh={hideModalAndRefresh}
            />
          </div>
        </Sheets>
      </Modal>
    </div>
  )
}
