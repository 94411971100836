import "@ingka/svg-icon/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/dual-button/style.scss";
import DualButton from "@ingka/dual-button";

export default function DualBtn(props) {
  return (
    <DualButton
        {...props}
      />
  )
}
