import { useTranslation } from "react-i18next";
import Text from '../ingka/Text';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import style from "./mainMenu.module.scss"
import { levelsForMotion } from "../../configuration/levelsForMotion";
import { useSelector } from "react-redux";


export default function MainMenu({changeView, openSelector}) {
  const [t] = useTranslation("global")
  const navigate = useNavigate()
  const levels = levelsForMotion
  const role = useSelector((state) => state.global.rol)

  return (
    <nav>
      <Text tagName="p" bodySize="xl" >
        <b>{t("menu.motions.title")}</b>
      </Text>
      <div className={style.submenu}>
      {levels && 
        levels.map( level =>
          <Link className="link link--subtle" onClick={() => openSelector(level)} key={level.id}>
            <Text tagName="p" bodySize="l" >
              {t(level.title)}
            </Text>
          </Link>
        )
      }
      </div>

      <NavLink className="link link--subtle" to="your-motions" onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t("menu.your-motions")}</b>
        </Text>
      </NavLink>
      <NavLink className="link link--subtle" to="motions" onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t("menu.motions-console")}</b>
        </Text>
      </NavLink>

      {role.name !== "COWORKER" &&
      <>
        <Text tagName="p" bodySize="xl" >
          <b>{t("menu.configuration.title")}</b>
        </Text>
        <div className={style.submenu}>
          <Link className="link link--subtle" to={`users`} onClick={changeView}>
            <Text tagName="p" bodySize="l" >
              {t('menu.configuration.users')}
            </Text>
          </Link>
          <Link className="link link--subtle" to={`actions`} onClick={changeView}>
            <Text tagName="p" bodySize="l" >
              {t('menu.configuration.actions')}
            </Text>
          </Link>
          <Link className="link link--subtle" to={`maps`} onClick={changeView}>
            <Text tagName="p" bodySize="l" >
              {t('menu.configuration.maps')}
            </Text>
          </Link>
        </div>
      </>
      }
    </nav>
  )
}
