import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/carousel/style.scss";
import "@ingka/tabs/style.scss";
import "@ingka/focus/style.scss";

export default function MyTab({tabs, tabPanels, defaultTab, onTabChanged}) {
  return (
    <Tabs
      onTabChanged={onTabChanged}
      tabs={
        tabs.map(tab =>
          <Tab {...tab}/>
        )
      } 
      tabPanels={
        tabPanels.map(panel =>
          <TabPanel {...panel}/>
        )
      } 
      defaultActiveTab={defaultTab}
      />
  )
}
