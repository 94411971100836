import { PercentToPixel } from "./pointsConverter"
import { filterIcon } from '@ingka/ssr-icon/paths/filters';

const calculateTime = (trackingTime) => {
  const time = trackingTime / 60
  let seconds
  let minutes
  if(time % 1 === 0){ //is int the time
    seconds = 0
    minutes = time
  }else{
    seconds = Math.round((time - Math.trunc(time)) * 60)
    minutes = Math.trunc(time)
  }
  return {minutes: minutes, seconds: seconds}
}

export function CalculateTimeMinAndSeconds(trackingTime) {
  const time = calculateTime(trackingTime)
  return((time.minutes < 10 ? "0": "") + time.minutes + ":" + (time.seconds < 10 ? "0": "") + time.seconds)        
}

export function CalculateTimeMinAndSecondsSeparate(trackingTime) {
  return(calculateTime(trackingTime))        
}

const mostPopular = (arr) => {
  let count = {};
  arr.forEach( x => {
    count[x] = (count[x] || 0) + 1;
  })
  const thePopular = Object.keys(count).reduce(function (a, b) {
    return count[a] > count[b] ? a : b;
  });
  return thePopular
}

export function CalculateStatistics(options, times, groups, ages, genders, nationalities, tools, actions){
  const data = {}
  data.totalMotions = times.length
  data.averageTime = CalculateTimeMinAndSeconds((times.reduce((a, b) => a + b, 0)) / times.length)
  data.minTime = CalculateTimeMinAndSeconds(Math.min(...times))
  data.maxTime = CalculateTimeMinAndSeconds(Math.max(...times))

  data.popularGroup = options.groupOptions.find(el => el.value === mostPopular(groups)).label
  data.popularGender = options.genderOptions.find(el => el.value === mostPopular(genders)).label
  data.popularAge = options.ageOptions.find(el => el.value === mostPopular(ages)).label
  data.popularNationality = options.nationalityOptions.find(el => el.value === mostPopular(nationalities)).label
  data.popularTool = options.toolsOptions.find(el => el.value === mostPopular(tools)).label

  data.totalActions = actions.length
  data.averageActionsMap = actions.length > 0 ? ((actions.length / times.length).toFixed(2)) : 0
  data.popularAction = actions.length > 0 ? options.actions.find(el => el.id == mostPopular(actions)).title : "No actions"
  return data
}

export function CalculateMotionsData(motions, canvasSize, trafficRadius, map){
  let dataHeat = [] //is an array to arrays [positionX, positionY, value]
  const VALUE_FOR_PT = 1
  //console.log("MOT", motions)
  //console.log("MAP", map)
  const diameter = (trafficRadius * 2)

  //build matrix for traffic canvas
  let trafficMatrix = []
  const columns = Math.ceil(canvasSize.w / diameter)
  const rows = Math.ceil(canvasSize.h / diameter)
  
  for(let i=0; i<rows; i++) {
    for(let j=0; j<columns; j++) {
      //trafficMatrix[[i,j]] = [j * diameter, i * diameter, 0]
      trafficMatrix.push([j * diameter, i * diameter, []])
    }
  }

  let markers = null
  const newMap = JSON.parse(JSON.stringify(map))
  let shortcuts = []
  let shortcutData = {
    vertical: 0,
    horizontal: 0,
    wc: 0,
    motionsWidthShortcut: []
  }
  if(map.markers?.length > 0 && map.level === 0){
    markers = newMap.markers.map((marker) => {
      const arrPoint = JSON.parse(marker.point)
      marker.point = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
      return marker
    })
  }

  let route = null
  let firstLine = []
  let lastLine = []
  if(map.route !== null && map.route !== undefined){
    const points = PercentToPixel(JSON.parse(newMap.route.point), canvasSize.w, canvasSize.h)
    route = points
    /* calculate area first and last route line */
    firstLine = [route[0], route[1]]
    lastLine = [route[route.length-2], route[route.length-1]]
  }
  let oppositeFlow = []

  motions.forEach((motion, indexMotion) => {
    let counter = []
    motion.path.forEach((path, index) => {
      if(index % 2 === 0){
        const xy = [path, motion.path[index+1]]

        /* HEAT MAP */
        const indexExit = dataHeat.findIndex(el => el[0] === xy[0] && el[1] === xy[1])
        if(indexExit === -1){
          dataHeat.push([...xy, VALUE_FOR_PT])
        }else{
          dataHeat[indexExit][2] += VALUE_FOR_PT
        }

        /* TRAFFIC MAP */
        trafficMatrix.forEach( cell => {
          if(xy[0] >= cell[0] && xy[0] < (cell[0] + diameter) && xy[1] >= cell[1] && xy[1] < (cell[1]+diameter)){
            if(!cell[2].includes(indexMotion)){
              cell[2] = [...cell[2], indexMotion]
            } 
          }
        })

        /* SHORTCUTS */ 
        if(markers){
          markers.forEach(marker => {
            if(xy[0] >= (marker.point[0][0]-20) && xy[0]<= (marker.point[0][0]+20) && xy[1] >= (marker.point[0][1]-20) && xy[1] <= (marker.point[0][1]+20)){ //this point is inside to marker
              if(!shortcuts.includes(indexMotion.toString()+"-"+marker.type.value+"-"+marker.id)){
                if(marker.type.value === "1" && Math.abs(path - motion.path[index+20]) > Math.abs(motion.path[index+1] - motion.path[index+21])){ //shortcut horizontal x+
                  shortcuts.push(indexMotion.toString()+"-"+marker.type.value+"-"+marker.id)
                  shortcutData.horizontal += 1
                }
                if(marker.type.value === "2" && Math.abs(path - motion.path[index+20]) < Math.abs(motion.path[index+1] - motion.path[index+21])){ //shortcut vertical y+
                  shortcuts.push(indexMotion.toString()+"-"+marker.type.value+"-"+marker.id)
                  shortcutData.vertical += 1
                }
                if(marker.type.value === "3"){ //WC
                  shortcuts.push(indexMotion.toString()+"-"+marker.type.value+"-"+marker.id)
                  shortcutData.wc += 1
                }
              }
            }
          })
        }

        /* OPPOSITE FLOW */
        if(route){
          route.forEach( point => {
            if(!counter.includes(point.toString())){
              if(xy[0] >= (point[0]-20) && xy[0]<= (point[0]+20) && xy[1] >= (point[1]-20) && xy[1] <= (point[1]+20)){
                counter.push(point.toString())
              }
            }
            
          })
        }

      }
    })
    
    if(counter.length > 0){
      const indexFirstItems = counter.findIndex(el => el === firstLine[0].toString() || el === firstLine[1].toString())
      const indexLastItems = counter.findIndex(el => el === lastLine[0].toString() || el === lastLine[1].toString())
      //Only routes that pass through the beginning and end of the official route are counted.
      if(indexFirstItems !== -1 && indexLastItems !== -1){
        if(indexFirstItems > indexLastItems) { //is opposite flow
          oppositeFlow.push(indexMotion)
        }
      }
    }
  })
  /* calculate motions with shortcut */
  const motionsId = shortcuts.map(el => {
    return el.split("-")[0]
  })
  const motionsIdReduce = new Set(motionsId)
  shortcutData.motionsWidthShortcut = [...motionsIdReduce]
  
  /* calculate percent */
  trafficMatrix.forEach( cell => {
    cell[2] = Math.round(cell[2].length * (100 / motions.length))
  })

  return {heat: dataHeat, traffic: trafficMatrix, shortcut: shortcutData, oppositeFlow: oppositeFlow}
}


export function amountActionPerAction(options, actions){
  const newOptions = options.actions.map( opt => {
    return {
      ...opt,
      amount: actions.filter( act => act == opt.id).length
    }
  })
  return newOptions
}