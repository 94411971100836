import { PercentToPixel } from "./pointsConverter"

export function CalculateCanvasSize(imageW, imageH) {
  const screenW = window.innerWidth
  const screenH = window.innerHeight
  const headerH = 70
  const consoleW = 160
  const consoleH = 74
  const padding = 24
  let maxH, maxW
  if(screenW > screenH){ //horizontal screen
    if(imageW < imageH){ //vertical picture
      maxH = screenH - padding - headerH - consoleH
      maxW = Math.round((imageW * maxH) / imageH)
    } else { //horizontal picture
      maxW = screenW - consoleW -(padding * 2)
      maxH = Math.round((imageH * maxW) / imageW)
      //proportions are broken
      if(maxH > (screenH - padding - headerH)){
        maxH = screenH - padding - headerH
        maxW = Math.round((imageW * maxH) / imageH)
      }
    }
  } else { //vertical screen
    if(imageW < imageH){ //vertical picture
      maxW = screenW - (padding * 2)
      maxH = Math.round((imageH * maxW) / imageW)
    } else { //horizontal picture
      maxW = screenW - (padding * 2)
      maxH = Math.round((imageH * maxW) / imageW)
    }
  }
  
  const size = {
    w: maxW,
    h: maxH
  }
  //console.log(size)
  return size
}

export function CalculatePolygonSize(points, w, h) {
  //console.log(points)
  const polygon = points.reduce((a, b) => a.concat(b), [])
  const minX = Math.min(...polygon.filter((_, i) => i % 2 === 0));
  const maxX = Math.max(...polygon.filter((_, i) => i % 2 === 0));
  const minY = Math.min(...polygon.filter((_, i) => i % 2 === 1));
  const maxY = Math.max(...polygon.filter((_, i) => i % 2 === 1));

  const wPx = maxX - minX
  const hPx = maxY - minY
  const size = {
    w: parseFloat(((wPx * 100) / w).toFixed(2)),
    h: parseFloat(((hPx * 100) / h).toFixed(2))
  }
  return size
}

export function CalculateBackgroundSize(canvasW, canvasH, polygonW, polygonH) {
  const size = {
    w: Math.round((canvasW * 100) / polygonW),
    h: Math.round((canvasH * 100) / polygonH)
  }
  return size
}

export function CalculateBackgroundPosition(points, w, h) {
  let xPoint = points[0][0]
  let yPoint = points[0][1]
  points.forEach(point => {
    if(point[0] <= xPoint){
      xPoint = point[0]
    }
    if(point[1] <= yPoint){
      yPoint = point[1]
    }
  })

  const data = {
    x: Math.round((xPoint * w) / 100),
    y: Math.round((yPoint * h) / 100),
  }
  return data
}

export function CalculateArea(points,w,h,position){
  //calculate area for paint the non-polygon areas
  const pointsPx = PercentToPixel(points, w, h)
  pointsPx.forEach(point => {
    point[0] = point[0]-position.x
    point[1] = point[1]-position.y
  })
  return pointsPx
}