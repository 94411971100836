import { useNavigate, useParams } from "react-router-dom"
import canvasStyle from "../assets/scss/canvas.module.scss"
import { useEffect, useRef, useState } from "react"
import Button from "../components/ingka/Button"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Group, Layer, Line, Path, Rect, Stage, } from "react-konva"
import SSRIcon from "@ingka/ssr-icon";
import save from "@ingka/ssr-icon/paths/database";
import flag from "@ingka/ssr-icon/paths/flag";
import clock from "@ingka/ssr-icon/paths/clock";
import setting from "@ingka/ssr-icon/paths/filters";
import undo from "@ingka/ssr-icon/paths/arrow-u-turn-backwards";
import redo from "@ingka/ssr-icon/paths/arrow-u-turn-forwards";
import { CalculateArea, CalculateBackgroundPosition, CalculateBackgroundSize, CalculateCanvasSize } from "../utils/calculateCanvasSize"
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/modal/style.scss";
import "@ingka/focus/style.scss";
import Settings from "../components/global/Settings"
import Badge from '../components/global/Badge';
import DualButton from "../components/ingka/DualButton"
import ActionSelector from "../components/global/ActionSelector"
import SaveMotion from "../components/global/SaveMotion"
import { getSection } from "../services/mapServices"
import Load from "../components/ingka/Load"
import { PercentToPixel, PercentToPixelForConcatArray, PixelToPercentForConcatArray } from "../utils/pointsConverter"
import {  saveMapMotion } from "../services/motionServices"
import { createMsgToast } from "../store/globalSlice"
import { getMotion } from "../services/viewingMotionsServices"
import Timer from "../components/global/Timer"

export default function NewMotion() {
  const [t] = useTranslation("global")
  const canvas = useRef()
  const canvasContainer = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const MAP_LEVEL = 0
  const SECTION_LEVEL = 1
  const SUBSECTION_LEVEL = 2
  const {motionId} = useParams()
  const canvasMode = useSelector((state) => state.setting.mode)
  const strokeWidth = useSelector((state) => state.setting.strokeWidth)
  const strokeColor = useSelector((state) => state.setting.strokeColor)

  const motion = useSelector((state) => state.global.motion)
  const maps = useSelector((state) => state.canvas.maps)
  const date = new Date()
  const[map, setMap] = useState(null)
  const[canvasSize, setCanvasSize] = useState(null)
  const[backgroundSize, setBackgroundSize] = useState(null)
  const[backgroundPosition, setBackgroundPosition] = useState(null)
 
  const [stopwatchOffset, setStopwatchOffset] = useState(null)
  
  const [isPause, setIsPause] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [timer, setTimer] = useState({m:0, s:0})

  const [modalActive, setModalActive] = useState(false)
  const [modalAction, setModalAction] = useState(null)
  const [disabledSave, setDisabledSave] = useState(true)

  const [line, setLine] = useState([])
  const [actions, setActions] = useState([])
  const [isDrawing, setIsDrawing] = useState(false)
  const [lastPoint, setLastPoint] = useState(null)
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(true)

  const [motionData, setMotionData] = useState(null)
  const[motionValues,setMotionValues] = useState(null)

  const[rawResponse, setRawResponse] = useState(null)
  const [area, setArea] = useState(null)

  const DRAFT_STATE = 1
  const FINALLY_STATE = 2

  useEffect(()=>{
    if(maps && motion){
      const map = maps.find(map => map.id === motion.map)
      if(motion.level === MAP_LEVEL){
        setMap(map) 
        setCanvasSize(CalculateCanvasSize(map.width, map.height))
        setLoading(false)
      }else{
        getSection(motion.section)
        .then( response => {
          let section
          setRawResponse({...response})
            if(motion.level === SUBSECTION_LEVEL){
              section = response.polygons.find(poly => poly.id === motion.subsection)
              section.width = response.width * (section.width/100)
              section.height = response.height * (section.height/100)
              section.points = JSON.parse(section.point)
              section.points.forEach(el => {
                el[0] = response.width * (el[0]/100)
                el[1] = response.height * (el[1]/100)
              })
              //console.log("subsection",section)
            }else{
              section = response
              section.points = JSON.parse(section.point)
            }

            if(map.image !== undefined){
              section.image = map.image
            }else{
              //if there is no map image, return to maps 
              navigate('dashboard')
            }
            
            const canvasSizePx = PercentToPixel([[section.width, section.height]],map.width, map.height)
            setCanvasSize(CalculateCanvasSize(canvasSizePx[0][0], canvasSizePx[0][1]))
            setMap(section)
          })
      }
      
    }
  },[maps, motion])

  useEffect(() => {
    if(canvasSize && motion.level !== MAP_LEVEL){
      setBackgroundSize(CalculateBackgroundSize(canvasSize.w, canvasSize.h, map.width, map.height))
    }
    
  }, [canvasSize])

  useEffect(() => {
    if(backgroundSize ){
      const points = JSON.parse(rawResponse.point)
      const position = CalculateBackgroundPosition(points, backgroundSize.w, backgroundSize.h)
      if(motion.level === SECTION_LEVEL){
        setBackgroundPosition(position)
        setArea(CalculateArea(points, backgroundSize.w, backgroundSize.h,position))
      }
      else if(motion.level === SUBSECTION_LEVEL){
        const subPosition = CalculateBackgroundPosition(map.points, backgroundSize.w, backgroundSize.h)
        position.x = position.x + subPosition.x
        position.y = position.y + subPosition.y
        setArea(CalculateArea(map.points, backgroundSize.w, backgroundSize.h,subPosition))
        setBackgroundPosition(position)
      }
      setLoading(false)
    }
  },[backgroundSize])

  useEffect(() => {
    //if is edit mode, not new
    if(canvasSize){
      if(motionId){
        getMotion(motionId).then(motion => {
          //console.log("Motion", motion)
          setStopwatchOffset(date.setSeconds(
            date.getSeconds() + motion.trackingTime
          ))
          setIsPause(true)
          const path = JSON.parse(motion.path)
          setLine(PercentToPixelForConcatArray(path, canvasSize.w, canvasSize.h))
          const act = motion.motionActions.map(el => {
            const point = PercentToPixelForConcatArray(JSON.parse(el.point), canvasSize.w, canvasSize.h)
            const icon = el.action.required ? el.action.icon.replaceAll("[", "").replaceAll("]", "").split(",")
            : JSON.parse(el.action.icon)
      
            return {point: point, iterationValue: el.iterationValue, ...el.action, icon:icon}
          })
          setActions(act)
          setMotionValues({
            gender:motion.gender,
            ageRange:motion.ageRange,
            group:motion.demographicGroup,
            nationality:motion.nationality,
            tool:motion.tool,
          })
        })
      }else{
        setStopwatchOffset(date)
      }
    }
    
  },[,motionId,canvasSize])


  const handleCanvasClick = (e) =>{
    if(timer.s === 0 && timer.m === 0){
      setIsStart(true)
    } else if (isPause){
      setIsStart(true)
      setIsPause(false)
    }
    setIsDrawing(true)
    const pos = e.target.getStage().getPointerPosition();
    setLine([...line, pos.x, pos.y]);
  }

  const handleCanvasUp = (e) => {
    setIsDrawing(false)
    const pos = e.target.getStage().getPointerPosition();
    setLastPoint([pos.x, pos.y]);
    changeHistory(actions)
  }

  const changeHistory = (actions) =>{
    let lastHistories = []
    if(history.length > 0){
      lastHistories = history.map( item => {
        item.current = false
        return item
      })
    }
    setHistory([...lastHistories, {line: line, actions: actions, current: true}])
    //console.log([...lastHistories, {line: line, actions: actions, current: true}])
  }

  const handleCanvasMouseMove = (e) =>{
    if (!isDrawing) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    setLine([...line, point.x, point.y])
  }

  const openModal = (action) => {
    setModalAction(action)
    setModalActive(true)

    if(action === "saveMotion"){
      setIsPause(true)
      setIsStart(false)
    }
  }

  const pushAction = (act) => {
    setModalActive(false)
    const action = {
      ...act,
      point: lastPoint
    }
    setActions([...actions, action])
    changeHistory([...actions, action]) 
  }

  const undoHistory = () => {
    const currentHistoryIndex = history.findIndex(item => item.current)
    const newHistory = history.map( (item, index) => {
      if(currentHistoryIndex-1 === index){
        item.current = true
      } else {
        item.current = false
      }
      return item
    }) 
    const currentHistory = newHistory.find(item => item.current)   
    setHistory(newHistory)
    setLine(currentHistory ? currentHistory.line : [])
    setActions(currentHistory ? currentHistory.actions : [])
  }

  const redoHistory = () => {
    const currentHistoryIndex = history.findIndex(item => item.current)
    if(currentHistoryIndex +1 < history.length){
      const newHistory = history.map( (item, index) => {
        if(currentHistoryIndex+1 === index){
          item.current = true
        } else {
          item.current = false
        }
        return item
      }) 
      const currentHistory = newHistory.find(item => item.current)   
      setHistory(newHistory)
      setLine(currentHistory ? currentHistory.line : [])
      setActions(currentHistory ? currentHistory.actions : [])
    }
    
  }

  const preSaveMotion = (data) =>{
    setMotionData(data)
    if(data.demographicGroup && data.ageRange && data.gender && data.nationality && data.tool){
      setDisabledSave(false)
    }
    
  }

  const saveMotion = (state) => {
    const data = {
      ...motionData,
      ...(motion.level === MAP_LEVEL ? {mapId: map.id} : (motion.level === SECTION_LEVEL ? {sectionId: map.id} : {sectionId: map.id})),
      path: JSON.stringify(PixelToPercentForConcatArray(line, canvasSize.w, canvasSize.h)),
      actions: actions.map( act => ({
        actionId: act.id,
        point:JSON.stringify(PixelToPercentForConcatArray(act.point, canvasSize.w, canvasSize.h)),
        ...(act.iteration !== "null" ? {iterationValue: act.iterationValue} : {})
      })),
      trackingTime: (timer.m*60) + timer.s,
      stateId: state
    }
    if(map.level !== 0){
      data.versionId = map.modificationDate ? map.modificationDate : map.creationDate
    }
    
    saveMapMotion(data, motionId).then(() => {
      dispatch(createMsgToast({body: t('motion.successPOSTMotion'), variant:"positive"}))
      navigate('/dashboard')
    })
  }

  useEffect(() => {
    if(canvasSize){
      const handleResize = () =>{
        if(canvasSize.w > canvasContainer.current.clientWidth || canvasSize.h > canvasContainer.current.clientHeight){
          alert(t('validation.screenSizeChange'))
        }
      }
      window.addEventListener('resize',handleResize)
    }
  },[canvasSize, canvasContainer])
  
  return (
    <>
      <div className={canvasStyle.container}>
      {map !== null && canvasSize !== null &&
        <div className={canvasStyle.canvas} ref={canvasContainer}>
          {loading &&
            <Load />
          }
          {!loading &&
          <div 
          className={`${canvasStyle.background} ${canvasMode}`} 
          style={{
            backgroundImage: `url("data:image/png;base64,${map.image}")`,
            width: canvasSize.w,
            height: canvasSize.h,
            backgroundSize: motion.level === MAP_LEVEL ? "contain" : `${backgroundSize.w}px ${backgroundSize.h}px`,
            backgroundPosition: motion.level === MAP_LEVEL ? "center" : `-${backgroundPosition.x}px -${backgroundPosition.y}px`
          }} 
          ref={canvas}
          >
            {canvasSize &&
              <Stage
              width={canvasSize.w}
              height={canvasSize.h}
              onMouseDown={handleCanvasClick}
              onMouseUp={handleCanvasUp}
              onTouchStart={handleCanvasClick}
              onTouchEnd={handleCanvasUp}
              onMouseMove={handleCanvasMouseMove}
              onTouchMove={handleCanvasMouseMove}
            >
              <Layer>
                {/* paint the non-polygon areas */}
                {area &&
                  <Group>
                    <Rect
                      x={0}
                      y={0}
                      width={canvasSize.w}
                      height={canvasSize.h}
                      fill="rgb(255,255,255,0.9)"
                    />
                    <Line
                      points={area.reduce((a, b) => a.concat(b), [])}
                      fill="rgb(0,0,0)"
                      closed
                      globalCompositeOperation='destination-out'
                    />
                  </Group>
                }
                <Line
                  points={line}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={'source-over'}
                />
                {actions &&
                  actions.map((action) =>
                  action.icon.map( (path, index) => 
                    <Path
                      key={index}
                      x={action.point[0] - 12}
                      y={action.point[1] - 12}
                      data={path}
                      fill= "rgb(0, 0, 0)"
                      scaleX= {1}
                      scaleY= {1}
                      />
                    )
                  )
                }
              </Layer>
            </Stage>
            }
          </div>
          }
        </div>
      }
        <div className={canvasStyle.console}>
          <div className={canvasStyle.firstZone}>
            <div className={canvasStyle.dataAction}>
              <SSRIcon paths={flag} />
              <Badge className="bg-blue">{actions.length}</Badge>
            </div>
            <div className={canvasStyle.dataTime}>
              <SSRIcon paths={clock} />
              {stopwatchOffset &&
                <Timer 
                stopwatchOffset={stopwatchOffset}
                isStart={isStart}
                isPause={isPause}
                updateTimer={(time) => setTimer(time)}
                />
              }
            </div>
            
            <Button 
              text={t("global.save")}
              type="secondary"
              ssrIcon={save}
              small
              onClick={() => openModal("saveMotion")}
              disabled={!lastPoint}
              />
          </div>
          <div>
            <Button 
              text={t("motion.newAction")}
              type="emphasised"
              ssrIcon={flag}
              small
              onClick={() => openModal("setAction")}
              disabled={!lastPoint}
              name="action"
            />
            <DualButton
              type="secondary"
              orientation="horizontal"
              small
              ssrIconFirst={undo}
              ssrIconSecond={redo}
              onClickFirst={undoHistory}
              onClickSecond={redoHistory}
              disabledFirst={history.length === 0 || history.find(el => el.current) === undefined}
              disabledSecond={history.length === 0 || history.findIndex(el => el.current)+1 === history.length}
              name="dualButton"
            />
            <Button 
              text={t("global.settings")}
              type="secondary" 
              ssrIcon={setting}
              small
              onClick={() => openModal("settings")}
              />
          </div>
        </div>
      </div>
      <Modal
        visible={modalActive}
        handleCloseBtn={()=> {
          setModalActive(!modalActive)
          setDisabledSave(true)
          }}>
          <Sheets
            aria-label="Accessibility header for a modal"
            className="example-prompt-override"
            footer={modalAction === "saveMotion" &&
              <ModalFooter>
                <Button text={t("global.save") + " " +t("global.draft").toLowerCase()} type="secondary" onClick={() => saveMotion(DRAFT_STATE)}/>
                <Button text={t("global.save")} type="emphasised" disabled={disabledSave} onClick={() => saveMotion(FINALLY_STATE)}/>
                {/* <Button text={t("global.cancel")} /> */}
              </ModalFooter>
              }
            header={<ModalHeader ariaCloseTxt="Close button text" title={t(`motion.${modalAction}Title`)}/>}
          >
            <div className="container">
              {modalAction === "saveMotion" &&
                <SaveMotion preSaveMotion={preSaveMotion} motionValues={motionValues}/>
              }
              {modalAction === "setAction" &&
                <ActionSelector actionSelected={pushAction}/>
              }
              {modalAction === "settings" &&
                <Settings canvasMode={true} paintControl={true}/>
              }
            </div>
          </Sheets>
        </Modal>
    </>
  )
}
