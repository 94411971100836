import '@ingka/focus/style.scss';
import '@ingka/switch/style.scss';
import SwitchIkea from '@ingka/switch';

export default function Switch({props}) {
  return (
    <SwitchIkea
      {...props}
    />
  )
}
