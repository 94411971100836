import axios from "axios";
import { store } from "../store/store";
import { doLogout } from "../store/globalSlice";


export async function apiFetch({
  method,
  url, 
  data, 
  body = false, 
  contentType = "application/json",
  authorization = true
  }) {
  const lang = localStorage.getItem("language")
  const token = localStorage.getItem("token")
  const header = {
      "Content-Type": contentType,
      "Accept-Language": lang,
      ...(authorization ? {'Authorization': 'Bearer '+token} : {})
    }

  try {
    let response
    response = await axios({
      method: method,
      url: process.env.REACT_APP_API+url,
      ...body ? { data: data } : { params: data },
      headers: header,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){
      const axiosError = {
        response: error.name,
        message: error.message
      }
      return axiosError
    }else if(error.response.status === 422){
      store.dispatch(doLogout())
      const axiosError = {
        response: error.code,
        message: error.response.data.message
      }
      return axiosError
    }else if(error.response.status === 404){
      window.location.href = "/not-found"
    }else{
      return error
    }
    
  }
}

export async function apiFetchIkea({method,url, data, body = false, contentType = "application/json"}) {
  const header = {
      "Content-Type": contentType,
      "x-client-id": process.env.REACT_APP_X_CLIENT_ID
    }

  try {
    let response
    response = await axios({
      method: method,
      url: process.env.REACT_APP_API_IKEA+url,
      ...body ? { data: data } : { params: data },
      headers: header,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){
      const axiosError = {
        response: error.name,
        message: error.message
      }
      return axiosError
    }else if(error.response.status === 404){
      window.location.href = "/not-found"
    }else{
      return error
    }
    
  }
}