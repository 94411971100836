/* eslint-disable react-hooks/exhaustive-deps */
import canvasStyle from "../../assets/scss/canvas.module.scss"
import { Arrow,Layer, Line, Path, Stage, Group, Rect, Text, Circle } from "react-konva"
import { useEffect, useRef, useState } from "react"
import { PercentToPixel } from "../../utils/pointsConverter"
import HeatMap from "../../configuration/heatMap"
import SliderInput from "../ingka/SliderInput"
import { useTranslation } from "react-i18next"
import TextCompo from "../ingka/Text"
import { CirclePicker } from "react-color"
import Switch from "../ingka/Switch"
import Button from "../ingka/Button"
import setting from "@ingka/ssr-icon/paths/filters";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";


export default function CanvasMotions({mode, map, motions, canvasSize, backgroundSize, backgroundPosition, area, actionOpt, heatData, trafficData, trafficRadius}) {
  const canvas = useRef()
  const canvasContainer = useRef()
  const SUBSECTION_LEVEL=2
  const SECTION_LEVEL=1
  const MAP_LEVEL=0
  const paddingBall = 3
  const TOILET = "3"
  const [t] = useTranslation("global")
  const [route, setRoute] = useState(null)
  const [addons, setAddons] = useState(null)
  const [markers, setMarkers] = useState(null)
  const [heatDraw, setHeatDraw] = useState(0.1)
  const [lineWidth, setLineWidth] = useState(1)
  const [strokeColor, setStrokeColor] = useState("#000")
  const [actions, setActions] = useState(null)
  const [actionOptions, setActionOptions] = useState(null)
  const [showRoute, setShowRoute] = useState(true)
  const [showMarkers, setShowMarkers] = useState(true)
  const [showAddons, setShowAddons] = useState(true)
  const [modalActive, setModalActive] = useState(false)
  const [addonSelected, setAddonSelected] = useState(null)
  const [startTrafficRange, setStartTrafficRange] = useState(1)
  const [endTrafficRange, setEndTrafficRange] = useState(100)


  useEffect(() => {
    //console.log("MAP", map)
    if(map.route !== null && map.route !== undefined){
      const points = PercentToPixel(JSON.parse(map.route.point), canvasSize.w, canvasSize.h)
      setRoute({id: map.route.id, points: points})
    }

    if(map.addons !== null && map.addons !== undefined && map.level !== SUBSECTION_LEVEL){
      if(map.addons.length > 0){
        const adds = map.addons.map((addon) => {
          const arrPoint = JSON.parse(addon.point)
          addon.point = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
          return addon
        })
        setAddons(adds)
      }
    }

    if(map.markers !== null && map.level !== SUBSECTION_LEVEL){
      if(map.markers.length > 0){
        const mark = map.markers.map((marker) => {
          const arrPoint = JSON.parse(marker.point)
          marker.point = PercentToPixel(arrPoint, canvasSize.w, canvasSize.h)
          return marker
        })
        setMarkers(mark)
      }
    }

    if(motions){
      const act = []
      motions.forEach(mot => {
        if(mot.actions.length > 0) act.push(...mot.actions)
      })
      if(act.length > 0) act.forEach(el => { el.show = true })
      setActions(act)

      const actOpt = actionOpt.map(act => {
        return {...act, show: true}
      })
      setActionOptions(actOpt)
    }
  },[])

  useEffect(() => {
    if(mode === "heatMap" && heatData){
      const canvasSimple = document.getElementById('heat_map')
      const heat = new HeatMap(canvasSimple, heatData);
      heat.draw(heatDraw); //This is to regulate the flow of paint.
    }
  },[mode, heatDraw])

  useEffect(() => {
    if(canvasSize){
      const handleResize = () =>{
        if(canvasSize.w > canvasContainer.current.clientWidth || canvasSize.h > canvasContainer.current.clientHeight){
          alert(t('validation.screenSizeChange'))
        }
      }
      window.addEventListener('resize',handleResize)
    }
  },[canvasSize, canvasContainer])

  const changeActions = (act) => {
    setActionOptions(actionOptions.map(el => el.id === act.id ? {...el, show: !el.show} : el ))
    setActions(actions.map(el => el.id === act.id ? {...el, show: !el.show} : el))
  }

  const clickAddon = (add) => {
    if(addonSelected === null){
      setAddonSelected(add)
    } else {
      if(addonSelected.id === add.id){
        setAddonSelected(null)
      } else {
        setAddonSelected(add)
      }
    }
  }

  return (
    <div className={`${canvasStyle.container} ${canvasStyle.view_motions}`}>
      <div className={canvasStyle.canvas} ref={canvasContainer}>
        <div 
        className={`${canvasStyle.background}`} 
        style={{
          backgroundImage: `url("data:image/png;base64,${map.image}")`,
          width: canvasSize.w,
          height: canvasSize.h,
          backgroundSize: backgroundSize,
          backgroundPosition: backgroundPosition
        }} 
        ref={canvas}
        >
          {mode === "heatMap" &&
            <canvas id="heat_map" width={canvasSize.w} height={canvasSize.h}></canvas>
          }
          {canvasSize && mode !== "heatMap" &&
            <Stage
            width={canvasSize.w}
            height={canvasSize.h}
            globalCompositeOperation="screen"
          >
            {/* LAYER FOR EXISTING POLYGONS AND ROUTE*/}
            <Layer>
              {/* paint the non-polygon areas */}
              {area &&
                <Group>
                  <Rect
                    x={0}
                    y={0}
                    width={canvasSize.w}
                    height={canvasSize.h}
                    fill="rgb(255,255,255,0.9)"
                  />
                  <Line
                    points={area.reduce((a, b) => a.concat(b), [])}
                    fill="rgb(0,0,0)"
                    closed
                    globalCompositeOperation='destination-out'
                  />
                </Group>
              }

              {/* FLOW MODE */}
              {mode === "flow" &&
                <>
                  {route && showRoute &&
                    route.points.map((point, index) => {
                      if(route.points.length !== index+1){
                        const fragment = [point[0],point[1],route.points[index+1][0],route.points[index+1][1]]
                        return(
                          <Arrow
                          key={index} 
                          points={fragment}
                          pointerLength={6}
                          pointerWidth={6}
                          fill='rgb(255, 255, 255)'
                          stroke="rgb(10, 138, 0)"
                          strokeWidth={2.5}
                          dash={[15, 5]}
                          />
                        )
                      }
                      return false;
                      }
                    )
                  }
                  {addons && showAddons &&
                    addons.map((addon, index) => 
                    <Group key={index}>
                      <Circle 
                      x={addon.point[0][0]}
                      y={addon.point[0][1]} 
                      radius={20} 
                      fill={"rgb(255, 255, 255, 0)"}
                      onPointerClick={()=> clickAddon(addon)}
                      />
                      <Path
                      x={addon.point[0][0] - 18}
                      y={addon.point[0][1] - 18}
                      data={addon.icon}
                      fill= {addonSelected && addonSelected.id === addon.id ? "rgb(204, 0, 8, 0.4)" : "rgb(204, 0, 8)"}
                      stroke={addonSelected && addonSelected.id === addon.id ? "rgb(204, 0, 8)" : ""}
                      strokeWidth={1}
                      scaleX= {1.5}
                      scaleY= {1.5}
                      fillRule="evenodd"
                      onPointerClick={()=> clickAddon(addon)}
                      />
                    </Group>
                    )
                  }
                  {markers && showMarkers &&
                    markers.map((marker, index) =>
                    <Group key={index}>
                      <Circle 
                      x={marker.point[0][0]}
                      y={marker.point[0][1]} 
                      radius={20} 
                      fill={"rgb(255, 255, 255, 0)"}
                      onPointerClick={()=> clickAddon(marker)}
                      />
                      <Path
                      x={marker.point[0][0] - 18}
                      y={marker.point[0][1] - 18}
                      data={marker.icon}
                      fill= {addonSelected && addonSelected.id === marker.id ? "rgb(204, 0, 8, 0.4)" : "rgb(204, 0, 8)"}
                      stroke={addonSelected && addonSelected.id === marker.id ? "rgb(204, 0, 8)" : ""}
                      strokeWidth={1}
                      scaleX= {marker.type.value === TOILET ? 1 :1.5}
                      scaleY= {marker.type.value === TOILET ? 1 :1.5}
                      fillRule="evenodd"
                      onPointerClick={()=> clickAddon(marker)}
                      />
                    </Group>
                    )
                  }
                  {motions.map((mot, index) => (
                    <Line
                      key={index}
                      points={mot.path}
                      stroke={strokeColor}
                      strokeWidth={lineWidth}
                      lineCap="round"
                      lineJoin="round"
                      globalCompositeOperation={'source-over'}
                    />
                  ))}
                  
                </>
              }

              {mode === "traffic" &&
              trafficData.map( (ball, index) => (
                ball[2] >= startTrafficRange && ball[2] <= endTrafficRange &&
                    <Group key={index}>
                      <Circle 
                      x={ball[0]+trafficRadius} 
                      y={ball[1]+trafficRadius} 
                      radius={trafficRadius - paddingBall} 
                      fill={ball[2] === 0 ? "rgb(150, 231, 247, 0.8)" : (ball[2] < 33 ? "rgb(140, 242, 109, 0.8)" : (ball[2] >= 33 && ball[2] < 66 ? "rgb(242, 228, 109, 0.8)" : "rgb(204, 120, 120, 0.8)"))}
                      stroke={ball[2] === 0 ? "rgb(0, 88, 163)" :(ball[2] < 33 ? "rgb(10, 138, 0)" : (ball[2] >= 33 && ball[2] < 66 ? "rgb(255, 219, 0)" : "rgb(204, 0, 8)"))}
                      />
                      <Text 
                      fontSize={12} 
                      fontStyle="bold"
                      text={ball[2]+"%"}
                      align="center"
                      verticalAlign="middle"
                      x={ball[0] }
                      y={ball[1] }
                      width={(trafficRadius * 2) - paddingBall}
                      height={(trafficRadius * 2) - paddingBall}
                      />
                    </Group>
                  )
                )
                }

              {/* ACTION MODE */}
              {mode === "actions" &&
                actions.map(action =>
                  action.show &&
                    action.icon.map( (path, index) => 
                    <Path
                      key={index}
                      x={action.point[0] - 12}
                      y={action.point[1] - 12}
                      data={path}
                      fill= "rgb(204, 0, 8)"
                      scaleX= {1}
                      scaleY= {1}
                    />
                ))
              }
            </Layer>
          </Stage>
          }
        </div>
      </div>
      
      {/* CONSOLE */}
      <div className={`${canvasStyle.console} ${canvasStyle.view_motions}`}>
        {mode === "flow" &&
          <div>
            {addonSelected && 
              <TextCompo tagName="h4" headingSize="s">
                {addonSelected.type.label}
              </TextCompo>
            }
            {map.level === MAP_LEVEL &&
              <Switch 
                props={{
                  name:"showRoute",
                  checked: showRoute,
                  onChange: (e)=> setShowRoute(e.target.checked),
                  label: t('settings.showRoute'),
                  className: "mb-2 mt-2"
                }}
              />
            }
            <Switch 
              props={{
                name: "showMarkers",
                checked: showMarkers,
                onChange: (e)=> setShowMarkers(e.target.checked),
                label: t('settings.showMarkers'),
                className: "mb-2"
              }}
            />
            {map.level === SECTION_LEVEL && 
              <Switch 
              props={{
                name: "showAddons",
                checked: showAddons,
                onChange: (e)=> setShowAddons(e.target.checked),
                label: t('settings.showAddons'),
                className: "mb-2 mt-2"
              }}
            />
            }
            
            
            <Button 
            text={t("global.settings")}
            type="secondary" 
            ssrIcon={setting}
            small
            onClick={() => setModalActive(!modalActive)}
            name="settingButton"
            />
          </div>
        }

        {mode === "heatMap" &&
          <SliderInput 
          className="mt-3"
          id="heatDraw"
          label={t('motions.heatDraw')}
          margin={0}
          range={{
            max: 1,
            min: 0.01
          }}
          start={[
            heatDraw
          ]}
          step={0.01}
          ariaFormat={{
            from: function noRefCheck(){},
            to: function noRefCheck(){}
          }}
          onChange={(e) => setHeatDraw(e[0])}
        />
        }

        {mode === "traffic" && 
        <SliderInput 
          className="mt-3"
          id="lineWidth"
          label={t('settings.trafficRange')}
          margin={0}
          range={{
            max: 100,
            min: 0
          }}
          start={[
            startTrafficRange,
            endTrafficRange
          ]}
          step={1}
          ariaFormat={{
            from: function noRefCheck(){},
            to: function noRefCheck(){}
          }}
          onChange={(e) => {
            setStartTrafficRange(e[0])
            setEndTrafficRange(e[1])
          }}
        />
        }

        {mode === "actions" &&
          <div>
            {actionOptions.map( action => 
              <div className={canvasStyle.cardAction + ' col-4'} key={action.id}>
                <div className={`${canvasStyle.boxAction} ${action.show ? canvasStyle.show : ''}`} onClick={() => changeActions(action)}>
                  <svg width="24" height="24" >
                    {action.icon.map( (path, index) => 
                      <path  d={path} key={index} fillRule="evenodd"/>
                    )}
                  </svg>
                  <TextCompo tagName="p" tagSize="m"><b>{action.title}({action.amount})</b></TextCompo>
                </div>
              </div>
            )}
          </div>
        }
      </div>

      <Modal
      visible={modalActive}
      handleCloseBtn={()=> {
        setModalActive(!modalActive)
        }}>
        <Sheets
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button text={t("global.close")} />
            </ModalFooter>
            }
          header={<ModalHeader ariaCloseTxt="Close button text" title={t(`map.settingsTitle`)}/>}
        >
          <div className="container">
            <SliderInput 
              className="mt-3"
              id="lineWidth"
              label={t('settings.drawWidthSimple')}
              margin={0}
              range={{
                max: 10,
                min: 1
              }}
              start={[
                lineWidth
              ]}
              step={1}
              ariaFormat={{
                from: function noRefCheck(){},
                to: function noRefCheck(){}
              }}
              onChange={(e) => setLineWidth(e[0])}
            />
            <TextCompo tagName="p" bodySize="l" className="mt-3">{t('settings.colorPickerSimple')}</TextCompo>
            <CirclePicker
              className="w-100 mx-a"
              color={strokeColor}
              onChangeComplete={(color) => setStrokeColor(color.hex)}
              colors={["#f44336", "#9c27b0", "#3f51b5", "#2196f3", "#4caf50", "#ffc107", "#ff9800", "#795548", "#000000"]}
            />
          
          </div>
        </Sheets>
      </Modal>
    </div>
  )
}
