import { useNavigate, useParams } from "react-router-dom"
import CanvasMap from "../components/global/CanvasMap"
import { useEffect, useState } from "react"
import { CalculateCanvasSize } from "../utils/calculateCanvasSize"
import { getMap } from "../services/mapServices"
import Load from "../components/ingka/Load"
import { useSelector } from "react-redux"

export default function Map() {
  const navigate = useNavigate()
  const {mapId} = useParams()
  const [loading, setLoading] = useState(true)
  const[canvasSize, setCanvasSize] = useState()
  const [map, setMap] = useState(null)
  const maps = useSelector((state) => state.canvas.maps)

  useEffect(()=>{
    getMapByID()
  },[])

  const getMapByID = () => {
    getMap({mapId: mapId})
      .then(map => {
        //console.log("Map",map)
        const image = maps.find(map => map.id === parseInt(mapId)).image
        if(image !== undefined){
          map.image = image
          map.addons = map.markers.map(marker => ({...marker, isMarker:true}))
          setMap(map)
          setCanvasSize(CalculateCanvasSize(map.width, map.height))
        }else{
          //if there is no map image, return to maps 
          navigate('maps')
        }
        
      })
      .finally(()=> setLoading(false))
  }

  return (
    <>
    {loading && 
      <Load />
    }
    {map && canvasSize &&
      <CanvasMap
      type="map"
      map={map}
      canvasSize={canvasSize}
      backgroundSize="contain"
      backgroundPosition="center"
      getData={getMapByID}
      level={0}
    />
    }
    </>
  )
}
