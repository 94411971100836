/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom"
import CanvasMap from "../components/global/CanvasMap"
import { useEffect, useState } from "react"
import { CalculateArea, CalculateBackgroundPosition, CalculateBackgroundSize, CalculateCanvasSize } from "../utils/calculateCanvasSize"
import { PercentToPixel } from "../utils/pointsConverter"
import { useSelector } from "react-redux"
import { getSection } from "../services/mapServices"
import Load from "../components/ingka/Load"

export default function Section() {
  const navigate = useNavigate()
  const {mapId, sectionId} = useParams()
  const [loading, setLoading] = useState(true)
  const[canvasSize, setCanvasSize] = useState(null)
  const [section, setSection] = useState(null)
  const[backgroundPosition, setBackgroundPosition] = useState(null)
  const[backgroundSize, setBackgroundSize] = useState(null)
  const [area, setArea] = useState(null)

  const maps = useSelector((state) => state.canvas.maps)

  useEffect(()=>{
    getSectionByID()
  },[])

  const getSectionByID = () => {
    getSection(sectionId)
    .then( section => {
      //console.log("Section",section)
        const image = maps.find(map => map.id === parseInt(mapId)).image
        if(image !== undefined){
          section.image = image
          setSection(section)
        }else{
          //if there is no map image, return to maps 
          navigate('maps')
        }
        section.addons = [
          ...section.addons.map(marker => ({...marker, isMarker: false})), 
          ...section.markers.map(marker => ({...marker, isMarker: true}))
        ]
      })
      .finally(()=> setLoading(false))
  }

  useEffect(() => {
    if(section){
      const map = maps.find(map => map.id === parseInt(mapId))
      const canvasSizePx = PercentToPixel([[section.width, section.height]],map.width, map.height)
      setCanvasSize(CalculateCanvasSize(canvasSizePx[0][0], canvasSizePx[0][1]))
    }
  },[section])

  useEffect(() => {
    if(canvasSize){
      //console.log("CanvasSize",canvasSize)
      setBackgroundSize(CalculateBackgroundSize(canvasSize.w, canvasSize.h, section.width, section.height))
    }
  },[canvasSize])

  useEffect(() => {
    if(backgroundSize){
      const points = JSON.parse(section.point)
      setBackgroundPosition(CalculateBackgroundPosition(points, backgroundSize.w, backgroundSize.h))
    }
  },[backgroundSize])
  
  useEffect(() => {
    if(backgroundPosition){
      const points = JSON.parse(section.point)
      setArea(CalculateArea(points, backgroundSize.w, backgroundSize.h, backgroundPosition))
    }
  },[backgroundPosition])

  return (
    <>
    {loading && 
      <Load />
    }
    {section && canvasSize && backgroundSize && backgroundPosition && area &&
      <CanvasMap
      type="section"
      map={section}
      canvasSize={canvasSize}
      backgroundSize={`${backgroundSize.w}px ${backgroundSize.h}px`}
      backgroundPosition={`-${backgroundPosition.x}px -${backgroundPosition.y}px`}
      area={area}
      getData={getSectionByID}
      level={1}
    />
    }
    </>
  )
}
