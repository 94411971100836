import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/search/style.scss";
import "@ingka/focus/style.scss";
import Search from "@ingka/search";

export default function SearchBar(props) {
  return (
    <Search
      {...props}
    />
  )
}
