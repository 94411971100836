import '@ingka/svg-icon/style.scss';
import '@ingka/hyperlink/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/slider/style.scss';
import '@ingka/focus/style.scss';
import Slider, { SliderInputControls } from '@ingka/slider';

export default function SliderInput(props) {
  return (
    <Slider
      {...props}
      ariaLabels={[
        'Selected value'
      ]}
    />
  )
}
