import IngkaInput from '@ingka/input-field';
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/focus/style.scss";
import FormField from "@ingka/form-field";
import style from "./input.module.scss"

export default function Input({formField, input}) {
  return (
    <FormField
      {...formField}
    >
      <IngkaInput
        className={style.input}
        {...input}
      />
    </FormField>
  )
}