/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import Select from "./Select"
import { useEffect, useState } from "react"
import { getSaveOptions } from "../../services/motionServices"

export default function SaveMotion({preSaveMotion, motionValues}) {
  const [t] = useTranslation("global")

  const [group, setGroup] = useState(null)
  const [age, setAge] = useState(null)
  const [gender, setGender] = useState(null)
  const [nationality, setNationality] = useState({value: "204", label: "España", hasSubOption: false}) //Spain default
  const [tools, setTools] = useState(null)

  const [groupOptions, setGroupOptions] = useState([])
  const [ageOptions, setAgeOptions] = useState([])
  const [genderOptions, setGenderOptions] = useState([])
  const [nationalityOptions, setNationalityOptions] = useState([])
  const [toolsOptions, setToolsOptions] = useState([])

  useEffect(() => {
    getSaveOptions().then(data => {
      setGroupOptions(data.demographicGroups)
      setAgeOptions(data.ageRanges)
      setGenderOptions(data.genders)
      setNationalityOptions(data.nationalities)
      setToolsOptions(data.tools)

      if(motionValues){
        //console.log(motionValues)
        if(motionValues.groups !== null){
          setGroup(data.demographicGroups.find(el => el.value == motionValues.group))
        }
        if(motionValues.ageRange !== null){
          setAge(data.ageRanges.find(el => el.value == motionValues.ageRange))
        }
        if(motionValues.gender !== null){
          setGender(data.genders.find(el => el.value == motionValues.gender))
        }
        if(motionValues.nationality !== null){
          setNationality(data.nationalities.find(el => el.value == motionValues.nationality))
        } 
        if(motionValues.tool !== null){
          setTools(data.tools.find(el => el.value == motionValues.tool))
        }
      }
    })
  },[])

  useEffect(() => {
      const data = {
        demographicGroup: group ? group.value : null,
        ageRange: age ? age.value : null,
        gender: gender ? gender.value : null,
        nationality: nationality ? nationality.value : null,
        tool: tools ? tools.value : null
      }
      //console.log(data)
      preSaveMotion(data)
  },[group, age, gender, nationality, tools])
  
  return (
    <div className="container mt-2">
      <Select
        formField={{className:"col-12"}}
        label={t("motion.demographicGroup")}
        onChange={(val) => {
          setGroup(val)
        }}
        isClearable={false}
        isSearchable={false}
        id="demographic_group"
        name="demographic group"
        options={groupOptions}
        value={group}
      />
      
      <Select
        formField={{className:"col-12"}}
        label={t("motion.ageRange")}
        onChange={(val) => {
          setAge(val)
        }}
        isClearable={false}
        isSearchable={false}
        id="age_range"
        name="age range"
        options={ageOptions}
        value={age}
      />

      <Select
        formField={{className:"col-12"}}
        label={t("motion.gender")}
        onChange={(val) => {
          setGender(val)
        }}
        isClearable={false}
        isSearchable={false}
        id="gender"
        name="gender"
        options={genderOptions}
        value={gender}
      />

      <Select
        formField={{className:"col-12"}}
        label={t("motion.nationality")}
        onChange={(val) => {
          setNationality(val)
        }}
        isClearable={false}
        isSearchable={true}
        id="nationality"
        name="nationality"
        options={nationalityOptions}
        value={nationality}
      />

      <Select
        formField={{className:"col-12"}}
        label={t("motion.shoppingTools")}
        onChange={(val) => {
          setTools(val)
        }}
        isClearable={false}
        isSearchable={false}
        id="shooping_tools"
        name="shooping tools"
        options={toolsOptions}
        value={tools}
      />
    </div>
  )
}
