import { useStopwatch } from "react-timer-hook"
import Text from '../ingka/Text';
import { useEffect } from "react";

export default function Timer({stopwatchOffset, isStart, isPause, updateTimer}) {

  const {seconds, minutes, start, pause } = useStopwatch({offsetTimestamp: stopwatchOffset})

  useEffect(() => {
    if(isStart){
      start()
    }
  },[isStart])

  useEffect(() => {
    if(isPause){
      pause()
    }
  },[isPause])

  useEffect(() => {
    updateTimer({m:minutes, s:seconds})
  },[isStart, isPause])

  return (
    <Text tagName="p" bodySize="xl">
      {minutes < 10 ? "0": ""}{minutes}:{seconds < 10 ? "0": ""}{seconds}
    </Text>
  )
}
