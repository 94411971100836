export function PixelToPercent(points, w, h) {
  points.forEach(point => {
    point[0] = parseFloat(((point[0] * 100) / w).toFixed(2))
    point[1] = parseFloat(((point[1] * 100) / h).toFixed(2))
  })
  return points
}

export function PercentToPixel(points, w, h) {
  points.forEach(point => {
    point[0] = Math.round((point[0] * w) / 100)
    point[1] = Math.round((point[1] * h) / 100)
  })
  return points
}

export function PixelToPercentForConcatArray(points, w, h) {
  const pxPoints = points.map((point, index) => {
    if(index % 2 === 0){
      return parseFloat(((point * 100) / w).toFixed(2))
    }else{
      return parseFloat(((point * 100) / h).toFixed(2))
    }
  })
  return pxPoints
}

export function PercentToPixelForConcatArray(points, w, h) {
  const pxPoints = points.map((point, index) => {
    if(index % 2 === 0){
      return Math.round((point * w) / 100)
    }else{
      return Math.round((point * h) / 100)
    }
  })
  return pxPoints
}