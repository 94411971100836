import InkaButton from "@ingka/button";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/focus/style.scss";

export default function Button(props) {
  return (
    <InkaButton {...props}
    small ={props.small ? true : false} 
    />
  )
}
