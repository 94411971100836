import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


//ACTIONS
export const getListActions =  () => {
  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const fetchSettings = {
    method: "GET",
    url: 'actions',
    data: {
      storeId: myStore.id
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      response.forEach(action => {
        if(action.required){
          action.icon = action.icon.replaceAll("[", "").replaceAll("]", "").split(",")
        }else{
          action.icon = JSON.parse(action.icon)
        }
      })
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const saveAction =  (data, type, actionId) => {
  const fetchSettings = {
    method: type,
    url: type === "POST" ?'action':`action/${actionId}`,
    data: data,
    body: true
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}
