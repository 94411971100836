import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


export default function PrivateRoutes({children}) {
  const isLogged = useSelector((state) => state.global.login);

  return (isLogged)
  ? children
  : <Navigate to="/login" />
}
