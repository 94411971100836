import { useTranslation } from "react-i18next";
import Table from "../components/global/Table";
import Text from "../components/ingka/Text";
import { useEffect, useState } from "react";
import Load from "../components/ingka/Load";
import { getMotions } from "../services/viewingMotionsServices";
import iconGo from '@ingka/ssr-icon/paths/arrow-right'
import Button from "../components/ingka/Button";
import { useNavigate } from "react-router-dom";
import { CalculateTimeMinAndSeconds } from "../utils/calculateAny";
import { useDispatch } from "react-redux";
import { setMotion } from "../store/globalSlice";

export default function YourMotions() {
  const [t] = useTranslation("global")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const user = window.localStorage.getItem('user')

  const [motions, setMotions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pageInfo, setPageInfo] = useState(null)

  const columns = [
    {
      accessorKey: "creationDate", 
      header: t('global.date'),
    },
    {
      accessorKey: "name", 
      header: t('yourMotions.motionIn'),
    },
    {
      accessorKey: "trackingTime",
      header: t('global.time'),
    },
    {
      accessorKey: "state.value",
      header: t('global.state'),
      Cell: ({row}) => 
        <span style={{color: row.original.state.id === 1 ? "rgb(204, 0, 8)" : "rgb(10, 138, 0)"}}>
          <b>{row.original.state.value}</b>
        </span>
    }
  ]

  useEffect(() => {
    getMyMotions(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getMyMotions = (page) => {
    getMotions({storeId: myStore.id , userId: user, page: page}).then(response => {
      const motions = response.content.map(mot =>{
        const motion = {
          id: mot.id,
          creationDate: mot.creationDate.slice(0,16).replace('T', ' / '),
          name: mot.map !== null ? mot.map.name : mot.section.name,
          level: mot.map !== null ? 0 : mot.section.level,
          map: mot.map !== null ? mot.map.id : mot.section.map_id,
          ...(mot.section !== null && mot.section.level === 1 ? {section: mot.section.id} : {}),
          ...(mot.section !== null && mot.section.level === 2 ? {section: mot.section.parentSection_id, subsection: mot.section.id} : {}),
          trackingTime: CalculateTimeMinAndSeconds(mot.trackingTime),
          state: mot.state
        }
        return motion
      })
      setMotions(motions)
      setPageInfo({rowCount: response.totalElements, pageCount: response.totalPages})
    }).finally(() => setLoading(false))
  }

  const goToMotion = (mot) => {
    const motion = {
      level: mot.level,
      map: mot.map,
      ...(mot.section ? {section: mot.section} : {}),
      ...(mot.subsection ? {subsection: mot.subsection} : {})
    }
    dispatch(setMotion(motion))
    navigate(`/motion/${mot.id}`)
  }

  return (
    <div>
      <Text tagName="h2" headingSize="m">{t('yourMotions.title')}</Text>
      {loading &&
        <Load />
      }
      {!loading && motions &&
        <Table 
          columns={columns} 
          data={motions}
          short={{id:'creationDate', desc: true}}
          enableRowActions={true}
          enablePagination={true}
          pageChange={(pageIndex) => getMyMotions(pageIndex)}
          pageInfo={pageInfo}
          renderRowActions= {({ row }) => {
            if(row.original.state.id === 1){//is draft
              return(
                <Button
                  type="tertiary"
                  small
                  iconOnly
                  ssrIcon={iconGo}
                  onClick={() => goToMotion(row.original)}
                />
              )
            }
          }}
          
          
        />
      }
      
    </div>
  )
}
