import { Route, Routes } from 'react-router-dom'
import Login from '../views/Login';
import PrivateRouter from './PrivateRouter';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

export default function PublicRouter() {
  return (
    <>
      <Routes>
        <Route path='login' element={
          <PublicRoutes >
            <Login />
          </PublicRoutes>
        }/>
        <Route path='/*' element={
          <PrivateRoutes>
            <PrivateRouter />
          </PrivateRoutes>
        }/>
      </Routes>
    </>
    
  )
}
