import React, { useEffect, useState } from 'react'
import style from "./actionSelector.module.scss"
import Text from "../../components/ingka/Text";
import Select from "./Select";
import Input from "../../components/ingka/Input";
import Button from '../ingka/Button';
import { useTranslation } from 'react-i18next';
import { getListActions } from '../../services/settingsServices';


export default function ActionSelector({actionSelected}) {
  const [t] = useTranslation("global")  
  const [actions, setActions] = useState(null)
  const [iterationAction, setIterationAction] = useState(null)
  const [iteration, setIteration] = useState("")

  const setAction = (action) => {
    if(action.iteration === "null"){
      actionSelected(action)
    } else {
      setIterationAction(action)
    }
  }

  useEffect(()=> {
    getListActions().then( actions => setActions(actions))
  },[])

  return (
    <>
    {actions && iterationAction === null &&
      <div className='row mt-2'>
        {
          actions.map( action => 
            <div className={style.card + ' col-4'} key={action.id}>
              <div className={style.box} onClick={() => setAction(action)}>
                <svg width="24" height="24" >
                  {action.icon.map( (path, index) => 
                    <path  d={path} key={index} fillRule="evenodd"/>
                  )}
                </svg>
                <Text tagName="p" tagSize="m"><b>{action.title}</b></Text>
              </div>
            </div>
        )}
      </div>
    }
    {iterationAction &&
      <div className='container'>
        {iterationAction.iteration === "select" &&
          <Select
            formField={{className:"col-12"}}
            label={iterationAction.iterationLabel}
            onChange={(val) => {
              setIteration(val.value)
            }}
            isClearable={false}
            isSearchable={false}
            id="map type"
            name="map type"
            options={iterationAction.iterationOptions.split(',').map(it => { return{value: it, label: it} })}
          />
        }

        {iterationAction.iteration === "input" &&
          <Input
            formField={{
              className: "col-12",
            }}
            input={{
              label: iterationAction.iterationLabel,
              value: iteration,
              onChange: e => {setIteration(e.target.value)}
            }}
          />
        }
        <Button
          className="col-12"
          text={t("global.save")}
          type="secondary"
          onClick={() => actionSelected({...iterationAction, iterationValue: iteration})}
          disabled={iteration === ""}
          />
      </div>
    }
    </>
  )
}
