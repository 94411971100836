import { useTranslation } from 'react-i18next';
import Text from '../components/ingka/Text';

export default function NotFound() {
  const [t] = useTranslation("global")

  return (
    <div style={{textAlign: "center"}}>
      <Text tagName="h1" headingSize="xl">
        Error 404
      </Text>
      <Text tagName="h3" headingSize="l">
        {t('global.notFound')}
      </Text>
    </div>
  )
}
