import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import { useSelector } from "react-redux";
import Message from "../components/ingka/Message";
import { useEffect, useState } from "react";
import { levelsForMotion } from "../configuration/levelsForMotion";
import style from "../assets/scss/dashboard.module.scss"
import MotionLevelSelector from "../components/global/MotionLevelSelector";
import { getListMaps } from "../services/mapServices";
import ProgressBar from "../components/global/ProgressBar";
import { getStatistics } from "../services/viewingMotionsServices";

export default function Dashboard() {
  const [t] = useTranslation("global")

  const userName = useSelector((state) => state.global.userName)
  const levels = levelsForMotion
  const [levelSelected, setLevelSelected] = useState(null)
  const [showSelector, setShowSelector] = useState(false)
  const [progress, setProgress] = useState([
    {made:0, total:0, title: t('dashboard.completeArea')},
    {made:0, total:0, title: t('dashboard.sectionArea')},
    {made:0, total:0, title: t('dashboard.subSectionArea')},
  ])

  const maps = useSelector((state) => state.canvas.maps)
  const allMaps = useSelector((state) => state.canvas.allMaps)
  const myStore = useSelector((state) => state.global.myStore)

  useEffect(() => {
    //if there arent all maps or maps is null, getMaps and save in store for first time
    if(!allMaps && maps === null){
      getListMaps()
    } 
  }, [allMaps, maps])

  const openSelector = (level) => {
    setLevelSelected(level)
    setShowSelector(true)
  }

  useEffect(() => {
    getStatistics().then(response => setTimeout(() => {
      const statistics = response.map(level => ({
        made: level.accumulationMovements,
        total: level.movementGoal,
        title: level.level === 0 ? t('dashboard.completeArea') : (level.level === 1 ? t('dashboard.sectionArea') : t('dashboard.subSectionArea'))
      }))
      setProgress(statistics)
    }, 200))
  },[])
  return (
    <div>
      <Text tagName="h2" headingSize="m">{t(`global.welcome`) + ", " + userName}</Text>
      <Text tagName="p" bodySize="m">{t(`dashboard.subtitle`)} {myStore.officeLocation}</Text>
      <div className="row">
        {levels && 
          levels.map( level =>
            <div className="col-lg-4 col-md-6 col-sm-12" key={level.id}>
              <Message
                onClick={() => openSelector(level)}
                className={style.card}
                variant="informative"
                title={
                  <div  className={style.body}>
                    {t(level.title)}
                    <img src={level.img} alt="Level" className={style.image}/>
                  </div>
                }
              />
            </div>
            
          )

        }
      </div>

      {/* MOTIONS PROGRESS */}
      <div className="mt-3 mb-3">
        <Text tagName="h3" className="mb-0" headingSize="m">{t(`dashboard.progress`)}</Text>
          {progress.map((pr,index) => (
            <ProgressBar key={index} made={pr.made} total={pr.total} title={pr.title}/>
          ))} 
      </div>

      {/* MODAL LEVEL SELECTOR FOR NEW MOTIONS */}
      <MotionLevelSelector 
      show={showSelector}
      level={levelSelected}
      hide={() => setShowSelector(!showSelector)}
      />
      
    </div>
  )
}
