import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal"
import Button from "../ingka/Button"
import Select from "./Select"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { setMotion } from "../../store/globalSlice"
import { useNavigate } from "react-router-dom"
import Text from "../ingka/Text"
import { getMap, getSection } from "../../services/mapServices"
import Load from "../ingka/Load"

export default function MotionLevelSelector({show, level, hide}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const MAP_LEVEL = 0
  const SECTION_LEVEL = 1
  const SUBSECTION_LEVEL = 2
  const maps = useSelector((state) => state.canvas.maps)
  const [disabledSave, setDisabledSave] = useState(true)
  const [map, setMap]= useState(null)
  const [section, setSection]= useState(null)
  const [subsection, setSubsection]= useState(null)
  const[mapsOptions, setMapsOptions] = useState([])
  const[sectionOptions, setSectionOptions] = useState([])
  const[subsectionOptions, setSubsectionOptions] = useState([])
  const[loading, setLoading] = useState(false)

  useEffect(() => {
    if(maps && maps.length > 0){
      setMapsOptions(maps.filter(map => map.active === 1).map( map => ({value: map.id, label:map.name})))
    }
  },[maps])

  useEffect(() => {
    //disabled button start
    if(level){
      switch(level.level){
        case MAP_LEVEL:
          map !== null? setDisabledSave(false) : setDisabledSave(true)
          
          break;
        case SECTION_LEVEL:
          map !== null && section !== null ? setDisabledSave(false) : setDisabledSave(true)
          
          break;
        case SUBSECTION_LEVEL:
          map !== null && section !== null && subsection !== null ? setDisabledSave(false) : setDisabledSave(true)
          
          break;
        default:
          setDisabledSave(true)
          break;
      }
    }
  }, [map, section, subsection])

  

  const getSectionOptions = (map) => {
    setLoading(true)
    getMap({mapId: map.value})
    .then(response => {
      if(response.polygons.length > 0){
        setSectionOptions(response.polygons.filter(pol => pol.active === 1).map(pol => ({value: pol.id, label:pol.name})))
      }
    })
    .finally(()=>setLoading(false))
  }

  const getSubsectionOptions = (sec) => {
    setLoading(true)
    getSection(sec.value)
    .then(response => {
      if(response.polygons.length > 0){
        setSubsectionOptions(response.polygons.filter(pol => pol.active === 1).map(pol => ({value: pol.id, label:pol.name})))
      }
    })
    .finally(()=>setLoading(false))
  }

  const goToNewMotion = () => {
    restart()
    const motion = {
      level: level.level,
      map: map.value,
      ...(section ? {section: section.value} : {}),
      ...(subsection ? {subsection: subsection.value} : {})
    }
    dispatch(setMotion(motion))
    navigate("/motion")
  }

  const restart = () => {
    hide()
    setMap(null)
    setSection(null)
    setSubsection(null)
    setDisabledSave(true)
  }

  return (
    <>
    {level &&
    <Modal
      visible={show}
      handleCloseBtn={restart}>
        <Sheets
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button text={t("global.start")} type="emphasised" disabled={disabledSave} onClick={goToNewMotion}/>
            </ModalFooter>
            }
          header={<ModalHeader ariaCloseTxt="Close button text" title={t('dashboard.selectLevel') + " " + t(level.title).toLocaleLowerCase()}/>}
        >
          <div className="container">
            {maps.length > 0 &&
            <>
              <Select
                formField={{className:"col-12"}}
                label={t("global.select") + " " + t("dashboard.completeArea").toLocaleLowerCase()}
                onChange={(val) => {
                  setMap(val)
                  setSection(null)
                  setSectionOptions([])
                  setSubsection(null)
                  setSubsectionOptions([])
                  getSectionOptions(val)
                }}
                isClearable={false}
                isSearchable={false}
                id="map type"
                name="map type"
                options={mapsOptions}
              />
              {level.level !== MAP_LEVEL && map !== null &&
                <Select
                  formField={{className:"col-12"}}
                  label={t("global.select") + " " + t("dashboard.sectionArea").toLocaleLowerCase()}
                  onChange={(val) => {
                    setSubsection(null)
                    setSubsectionOptions([])
                    setSection(val)
                    getSubsectionOptions(val)
                  }}
                  isClearable={false}
                  isSearchable={false}
                  id="map type"
                  name="map type"
                  defaultValue={section}
                  value={section}
                  options={sectionOptions}
                />
              }
              
              {level.level === SUBSECTION_LEVEL && map !== null && section !== null &&
                <Select
                  formField={{className:"col-12"}}
                  label={t("global.select") + " " + t("dashboard.subSectionArea").toLocaleLowerCase()}
                  onChange={(val) => setSubsection(val)}
                  isClearable={false}
                  isSearchable={false}
                  id="map type"
                  name="map type"
                  defaultValue={subsection}
                  value={subsection}
                  options={subsectionOptions}
                />
              }
            </>
            }
            {maps.length === 0 &&
              <Text tagName="p" bodySize="m">{t(`dashboard.noMaps`)}</Text>
            }
            {loading &&
              <Load />
            }
          </div>
        </Sheets>
      </Modal>
      }
      </>
  )
}
